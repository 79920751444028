.dropdown-menu-toggle {
  cursor: pointer;
}

.dropdown-menu-body {
  border: solid 1px lightgrey !important;
  padding: 15px !important;
  margin-top: -15px !important;
}

.dropdown-menu-header {
  color: #686e82 !important;
  margin-bottom: 0px !important;
}

.logout-button {
  border-color: #525252;
  background-color: #686e82;
  border-radius: 3px !important;
  color: white !important;
}

.logout-button:hover {
  color: white !important;
  background-color: #759fbc !important;
}

.dropdown-item-custom {
  padding-left: 0px;
}

.dropdown-item-custom:hover {
  color: #759fbc !important;
  text-decoration: underline !important;
  background: white !important;
}

.nav-link-font-size {
  font-size: 18px !important;
}

.support-link {
  color: white !important;
}

.support-link:hover {
  color: white !important;
}

.topBarSearchBtn {
  position: inherit;
  top: 0px;
  display: block;
  color: #ffffff;
  font-size: 13px;
  border: none;
  right: 0;
  cursor: pointer;
  background: #759fbc;
  height: 34px;
  padding: 0 20px;
  border-radius: 0 30px 30px 0;
}

.pacTopBarSearchBtn {
  position: inherit;
  top: 0px;
  display: block;
  color: #ffffff;
  font-size: 13px;
  border: none;
  right: 0;
  cursor: pointer;
  background: #759fbc;
  height: 34px;
  padding: 0 20px;
  border-radius: 0 30px 30px 0;
}

.marginRight {
  margin-right: 1vh;
}

.searchTextTopBar {
  width: 40vh !important;
}

.memberNumberLabel {
  font-size: 11px !important;
  margin-bottom: 0px !important;
  clear: both;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}

.live-searched {
  background: white;
  width: 86%;
  position: absolute;
  top: 33px;
  left: 25px;
  border-radius: 2px;
  box-shadow: 0px 2px 5px 0 #423d3d8a;
}

.live-searched .searched-item {
  border-bottom: 1px solid #bfbfbf;
  padding: 2px 10px 0;
  cursor: pointer;
  z-index: 290;
}

a.anker:hover {
  color: #0056b3 !important;
}

.live-searched .searched-item .value {
  flex: 0 0 77%;
  max-width: 77%;
}

.live-searched .value h4 {
  display: inline-block;
  font-size: 15px;
  color: #000000;
  margin: 0;
  vertical-align: top;
  margin-left: 2px;
  font-weight: 500;
}

.live-searched i {
  font-size: 9px;
  position: relative;
  top: -2px;
  color: #737373;
  left: -5px;
}

.live-searched .value .member {
  font-size: 12px;
  color: #4a4a4a;
  font-weight: 400;
  margin: 0;
  margin-left: 10px;
  font-style: italic;
}

.email-phone a.anker i {
  top: -0.6px;
  color: #737373;
}

.live-searched .searched-item .zdc-arrow {
  text-align: right;
  flex: 0 0 23%;
  max-width: 23%;
}

.live-searched .email-phone {
  display: inline-block;
  margin: 0;
  position: relative;
  margin-left: 0;
  top: -4px;
}

.email-phone a.anker {
  font-size: 12px;
  color: #626ed4;
  margin-left: 2px;
  z-index: 320;
}

.email-phone p {
  display: block;
  color: #353535;
  font-weight: 400;
  margin-left: 10px;
  font-size: 12px;
  margin: 0;
  position: relative;
  top: -3px;
}

.email-phone p i {
  top: -1px;
  color: #737373;
  font-size: 8px;
}

.live-searched .searched-item .zdc-arrow p {
  font-size: 12px;
  font-weight: 500;
  color: #4e4e4e;
  margin: 0;
}

.zdc-arrow span i {
  position: relative;
  right: 0;
  top: -5px;
  color: #759fbc;
  font-size: 15px;
}

nav.navbar-custom-searchbox {
  z-index: 300;
}

.content-page {
  z-index: 200;
}

.searched-item:last-child {
  border: 0px;
}

.live-searched .searched-item:hover {
  transition: background-color 0.3s ease;
  background: #eeeef3;
}

.searched-scroll {
  max-height: 303px;
  overflow: auto;
}

.search-view-all {
  margin: 0;
  padding: 2px 15px;
  width: 100%;
  text-align: right;
  border-top: 1px solid #bfbfbf;
  background: #ececf1;
}

.search-view-all p i {
  left: -3px;
  top: 0;
  color: #737373;
}

.search-view-all p {
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: 600;
  color: #759fbc;
  font-family: sans-serif;
  cursor: pointer;
}

.search-not-found {
  width: 100%;
  text-align: center;
  padding: 25px 0 20px;
}

.search-not-found h4 {
  font-size: 14px;
  font-family: sans-serif;
  margin: 0;
  font-weight: 500;
  color: #191919;
}

@media (max-width: 1460px) {
  a.anker {
    display: block;
    width: 100% !important;
  }

  .zdc-arrow span i {
    vertical-align: middle;
    top: -4px;
  }
}

a.row-search-bar {
  display: flex;
  flex-wrap: wrap;
}

.live-searched .member-no {
  font-size: 13px;
  color: #4a4a4a;
  font-weight: 400;
  margin: 0;
  font-style: italic;
  margin-left: 5px;
}

.cell-phone {
  color: black !important;
  font-weight: 600 !important;
  font-size: 9px !important;
}

@media (max-width: 470px) {
  .live-searched .value h4 {
    margin-bottom: 3px !important;
  }

  .live-searched span.member-no {
    display: block;
    margin-left: 7px;
  }
}

#sidebar-menu > ul > li > button {
    color: #e6e7e8; 
    display: block; 
    padding: 13px 20px; 
    font-size: 13.3px; 
    position: relative; 
    transition: all 0.5s; 
}
#sidebar-menu > ul > li > a {
    color: #e6e7e8;
}
#sidebar-menu > ul > li > a.active {
    color: #ffffff !important;
    background-color: #cb2630;
}
a.logo {
    color: #ffffff;
}





  




.FilterSearch {
  background: #696969;
}

.btn {
  border-radius: 14px;
  font-size: 14px;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(2rem + 1px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 1.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.displayInline {
  display: flex;
}

hr {
  margin-bottom: 0rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.borderLine {
  border-bottom: 1px solid #e9ecef;
}
.filterSelected {
  display: flex;
  /* border: 0; */
  margin-top: 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  margin-left: 1vh;
  cursor: pointer;
}

.filterSelectedMobile {
  display: flex;
  /* border: 0; */
  margin-top: 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
}

.collapsBtn {
  width: 100%;
  border-radius: 0;
  padding: 11px 20px;
  background: #686e82;
  text-align: left;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.9px;
  border: none;
  transition: background-color 0.5s ease;
  color: white;
  cursor: pointer;
  margin: 4px 0;
  vertical-align: middle;
  justify-content: space-between;
  display: flex;
}
.collapseChildTab {
  width: 100%;
  border-radius: 0;
  padding: 11px 20px;
  background: #686e82;
  text-align: left;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.9px;
  border: none;
  transition: background-color 0.5s ease;
  color: white;
  cursor: pointer;
  margin: 1px 0;
  vertical-align: middle;
  justify-content: space-between;
  display: flex;
}

.collapsBtnFirst {
  width: 100%;
  border-radius: 0;
  padding: 11px 20px;
  background: #686e82;
  text-align: left;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.9px;
  border: none;
  transition: background-color 0.5s ease;
  color: white;
  cursor: pointer;
  margin: 0 0 2px 0 !important;
  vertical-align: middle;
  justify-content: space-between;
  display: flex;
}

.mobileCollapsBtn {
  width: 100%;
  border-radius: 0;
  padding: 8px 14px;
  background: #759fbc;
  text-align: left;
  font-size: 12px;
  font-weight: 450;
  letter-spacing: 0.9px;
  border: none;
  transition: background-color 1s ease;
  color: white;
  cursor: pointer;
  /* margin: 4px 0; */
  vertical-align: middle;
  justify-content: space-between;
  display: flex;
}

.arrow-icon {
  font-size: 12px;
  margin-top: 5px;
  transition: 0.5s ease all;
}

.collapsBtn:hover {
  background: #dd333d;
}

.AdvanceFilterModal a.active.nav-link {
  color: white;
}

.AdvanceFilterModal a.active.nav-link {
  background: #759fbc;
}

.AdvanceFilterModal a.nav-link {
  cursor: pointer;
  transition: 0.5s ease all;
}

.totalCountMmeber {
  font-size: 13px;
  color: #222222;
  font-style: italic;
  margin-right: 13px;
}
.MobiletotalCountMmeber {
  font-size: 13px;
  color: #222222;
  font-style: italic;
  text-align: center;
}

.spac-remove {
  padding: 0px;
  margin-left: -1px;
  border: 1px solid #e5e5e5;
}

.bottom-stick {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.apply-btn-wrap {
  position: absolute;
  left: 0;
  bottom: 50px;
  right: 10px;
}
/* html {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
} */
.collapsParentAccord {
  border-radius: 0;
  padding: 9px 10px;
  background: #cb2630;
  text-align: left;
  font-size: 80%;
  font-weight: 450;
  letter-spacing: 0.9px;
  border: none;
  transition: background-color 0.5s ease;
  color: white;
  cursor: pointer;
  margin: 1px 0;
  vertical-align: middle;
  justify-content: space-between;
  display: flex;
}

.collapsParentAccordActive {
  border-radius: 0;
  padding: 10px 10px;
  background: #cb2630;
  text-align: left;
  font-size: 80%;
  font-weight: 450;
  letter-spacing: 0.9px;
  border: none;
  transition: background-color 0.5s ease;
  color: white;
  cursor: pointer;
  margin: 1px 0;
  vertical-align: middle;
  justify-content: space-between;
  display: flex;
}

.tabSection {
  width: 100%;
  border-radius: 0;
  padding: 8px 14px;
  background: #333547;
  text-align: left;
  font-size: 80%;
  font-weight: 425;
  letter-spacing: 0.7px;
  border: none;
  transition: background-color 0.5s ease;
  color: white;
  cursor: pointer;
  margin: 1px 0;
  vertical-align: middle;
  justify-content: space-between;
  display: flex;
}

.mobileSection {
  background: #333547;
  padding-right: 0px !important;
  padding-left: 0px !important;
  padding-right: 0;
  padding-left: 0;
}

.form-group > label {
  font-weight: 500;
}
/* .parentMobileDiv>.collapse > div {
  background: #333547;
  padding-right: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
} */

.parentMobileDiv > .collapse > .card-body:nth-child(1) {
  background: #ced4da !important;
}
.parentMobileDiv > .collapse > .card-body:nth-child(1) > div {
  background: #333547 !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
}
/* .card-body > :first-child {
  background: yellow;
} */
/* .parentMobileDiv>.collapse  {
  background: #cb2630 !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
} */

/* .parentMobileDiv > div > .collapse > form > .row > .col > span > label {
  color: white !important;
} */

.textAlignRight {
  text-align: end;
}

/* .parentMobileDiv
  > div
  > .collapse
  > form
  > .row
  > .col
  > .custom-control
  > label {
  color: white !important;
} */

/*
.parentMobileDiv{
  max-height:calc(100vh - 370px);
  overflow-y: auto;
  background: #ced4da !important;
} */

/* .modal {
  height: 100vh;
  height: calc((var(--vh, 1vh) * 100));
  background: blue !important; 
  overflow-y: scroll; 
} */
.mobile-modal-dialog {
  /* display: flex;
  width: auto;
  height: 70vh;
  height: calc((var(--vh, 1vh) * -569));*/
  /* background: green !important; */
  max-width: 99%;
}
/* .modal-content {
  height: 100vh;
  height: calc((var(--vh, 1vh) * 260)); 
   background: purple !important;
} */
.btn-filter-sm {
  font-size: 11.66667px !important;
  border-radius: 1.8vh;
}
.customMobileHeader {
  max-height: calc((1vh * 15));
  max-height: calc((var(--vh, 1vh) * 15));
}
.modal-body-custom {
  max-height: calc((1vh * 100));
  max-height: calc((var(--vh, 1vh) * 100));
  /* background: orange !important; */
}

.parentMobileDiv {
  /* height: calc((var(--vh, 1vh) * 40)); */
  max-height: calc((1vh * 35));
  max-height: calc((var(--vh, 1vh) * 35));
  background: #ced4da !important;
  overflow-y: scroll;
}
.mobFilterSpan {
  max-height: calc((1vh * 10));
  max-height: calc((var(--vh, 1vh) * 10));
}
.tagsScroll {
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: calc((1vh * 10));
  max-height: calc((var(--tagsScroll, 1vh) * 10));
  /* display: flex; */
}

.footerBtns {
  max-height: calc((1vh * 23));
  max-height: calc((var(--vh, 1vh) * 23));
  overflow: auto;
  overflow-x: hidden;
}

.removeYScroll {
  overflow: hidden;
  /* position: fixed; */
}
.headerMobileColor {
  color: white !important;
}
.parentMobileDiv > div > .tabSection > a {
  margin-right: auto;
}

.parentMobileDiv > div > .mobileCollapsBtn > a {
  margin-right: auto;
}
.mobileHeaderBtns > button {
  margin-right: 1.2vw;
  border-radius: 1.8vh !important;
  /* margin: 0.5vw; */
}

.modalScroll {
  -webkit-overflow-scrolling: touch;
  max-height: calc(100vh - 210px);
  overflow-y: auto;
  overflow-x: hidden;
}
/* When the screen width is 1100 pixels or larger. */
@media (min-width: 1100px) {
  .modalDefaultHeight {
    min-height: 45rem;
  }
}
.smallFont {
  font-size: 88%;
  font-weight: 400;
}

.mobileModalHeader {
  display: block;
}
.mobileSideBarResults {
  color: white;
  font-size: 2.8vw;
}
.btn-apply {
  background: #759fbc;
}
.webHeader {
  color: white;
  background: #759fbc;
  padding: 12px 15px;
  margin: 0 -1px;
}

.dynamicCheckBoxLayout {
  display: grid;
}

.fatrashBtn {
  background-color: #759fbc;
  height: calc(2rem + 1px) !important;
  color: white;
  border-radius: 0;
}
.faAddBtn {
  background-color: #759fbc;
  height: calc(2rem + 1px) !important;
  color: white;
  border-radius: 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.modal-dialog-margin {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
ul {
  list-style-type: none;
  padding-left: 0;
  display: inline;
}

.displayFlex {
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 760px) {
  .advancefilterMT {
    color: white;
  }
}

.AdvanceFilterModal .modal-header .close {
  padding: 1rem 1rem;
  margin: 0;
  color: white;
}

.chechbox-list {
  -webkit-columns: 150px;
  columns: 150px;
  -webkit-column-gap: 2em;
  -moz-column-gap: 2em;
  grid-column-gap: 5em;
  -webkit-column-gap: 5em;
          column-gap: 5em;
  margin: 0;
}

.chechbox-list li.chechbox-list_item { 
  display: flex;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  padding: 4px 0;
}
.filter-fields-scroll {
    max-height: calc(100vh - 65vh);
    overflow: auto;
    display: flex;
    padding: 18px 20px;
}
.fe-filter--scroll {
    max-height: calc(100vh - 65vh);
    overflow: auto; 
    display: flex;
    padding: 18px 20px;
    flex-wrap: wrap;
    flex-direction: column;
}
.fe-filter--scroll-mobile {
    max-height: calc(100vh - 65vh);
    overflow: auto;     
    padding: 18px 20px; 
}
.height-scroll {
  height: 35px;
}
 
.header-back {
  background: #759fbc;
  margin-top: -1px;
}
.AdvanceFilterModal .custom-checkbox label.custom-control-label {
  font-size: 15px;
  letter-spacing: 0.5px;
}
.borderNone {
  border: none;
  margin: -1px;
}
.applyfilter {
  float: right;
  /* margin-bottom: 8px; */
}
.apply-filter-selection {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.mobileHeadBtns {
  padding-right: 0.5rem;
}

.filter-selected {
  background: #e6e6e6;
  padding: 5px 12px;
  margin-left: 10px;
  border-radius: 6px;
  display: inline-block;
  margin-bottom: 8px;
  cursor: pointer;
}
.filter-selected a {
  color: #4a4a4a;
  margin-left: 10px;
  cursor: pointer;
}
.memberNumId {
  border-radius: 0;
}

.footerBtns {
  border-top: 0;
}

.mobileUIFilter {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
}

.field-array-scroll {
  max-height: calc(100vh - 400px);
  overflow: auto;
  display: flex;
  padding-left: 20px;
}
.mobileFilterSelectSpan {
  align-items: center;
}

.mobFilterSpan {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background: #f8f9fa;
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0;
}

.bottomBorderMobile {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.memberpage-tags {
  padding: 0 0 5px 12px;
}
.memberpage-tags .filter-selected {
  background: #e0e0ea;
  padding: 8px 15px;
  font-size: 15px;
  border-radius: 10px;
}
.feequip-tags {
  padding: 1rem 0 0 1.3rem;
}
.feequip-tags .filter-selected {
  background: #e0e0ea;
  padding: 8px 15px;
  font-size: 15px;
  border-radius: 10px;
}
.filtersBorder {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.setTagsPadding {
  padding-right: 0;
  padding-left: 2.6vh;
  width: 100%;
  margin-right: 0;
  position: relative;
  left: 3px;
}
.setFETagsPadding {
  padding-right: 0;
  padding-left: 2.6vh;
  width: 100%;
  margin-right: 0;
  position: relative;
  left: 3px;
}
.totalCount {
  padding-left: 0.5vh;
}
.ButtonsRadius > .btn {
  border-radius: 1.8vh !important;
}

.ButtonsRadius > .btn {
  border-radius: 1.8vh !important;
}

.custom-control-input {
  position: relative;
  z-index: -1;
  opacity: 0;
}

.spinnerPosition {
  position: absolute;

  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;

  background-size: 100%;
}

.spinner-grow-sm {
  width: 1rem;
  height: 0.6rem;
}
.longTextchbx {
  -webkit-columns: 215px;
  columns: 215px;
}
.longTextBudgetLineChbx {
  -webkit-columns: 350px;
  columns: 350px;
}
.subheading-chks {
  font-size: 16px;
  font-family: "Sarabun", sans-serif;
  font-weight: 600;
  margin: 0 0 5px 5px;
}
.address-padding {
  padding: 20px 20px 0 20px;
}
.ad-range-box {
  padding: 0 40px 0 20px;
}
.range-slider {
  padding: 0 8px;
}
span.input-range__label-container {
  font-size: 16px;
  color: black;
  font-family: inherit;
  letter-spacing: 1.2px;
}
/* 
.input-range__slider {
  background: #759fbc;
  border: 1px solid #759fbc;
}
.input-range__track--active {
  background: #759fbc;
} */

.input-range__label--value {
  top: -2.2rem !important;
}
.input-range__label--min,
.input-range__label--max {
  top: 1.5rem;
}
.search-ad {
  padding: 12px 15px 0 15px;
}

.parentMobileDiv .row {
  margin: 0;
}
.parentMobileDiv span.input-range__label-container {
  color: white;
}
.parentMobileDiv .ad-range-box {
  padding: 0 40px 20px 20px;
}

/* Advanced filter save search controls */
.advanced-search-container input,
.advanced-search-container button {
  min-height: 36px !important;
}
.advanced-search-container .btn-info2 {
  cursor: pointer;
  background: #759fbc !important;
  color: #ffffff;
}
.advanced-search-container button + div.dropdown {
  margin-left: 8px !important;
}
.advanced-search-container
  .dropdown-menu
  div.accordion
  div.card
  div.card-header {
  padding: 4px 4px 4px 4px !important;
}
.advanced-search-container .dropdown-menu div.accordion div.card {
  margin-bottom: 0px !important;
}
.advanced-search-container .dropdown-menu.show {
  margin-top: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.advanced-search-container
  div.dropdown
  div.accordion
  div.card
  div.card-header
  button.btn-link {
  text-decoration: none !important;
}
.advanced-search-container div.dropdown div.accordion div.card-header:hover {
  background-color: #028b81 !important;
  color: white !important;
}
.advanced-search-container
  div.dropdown
  div.accordion
  div.card
  button.btn-link:hover {
  color: white !important;
}
.advanced-search-container div.dropdown div.accordion div.card div.card-body {
  padding: 4px 4px 4px 24px !important;
  background-color: #ececf1 !important;
  font-size: 14px;
}
.advanced-search-container div.dropdown div.dropdown-menu.show {
  min-width: 14rem !important;
  /* min-height: 50vh !important; */
  height: auto !important;
}
.advanced-search-container div.card-body ul li {
  padding-top: 4px !important;
}
.advanced-search-container div.card-body ul li + li {
  border-top: dashed 1px #5b626b !important;
}
.advanced-search-container div.card {
  /* border-bottom: solid 1px #5b626b !important; */
}
.advanced-search-container div.dropdown-menu.show {
  /* border-top: solid 1px #5b626b !important;
  border-right: solid 1px #5b626b !important;
  border-left: solid 1px #5b626b !important; */
  border: solid 1px #5b626b !important;
  /* z-index: 1000 !important; */
}
.advanced-search-container div.card-body ul li,
.advanced-search-container div.card-body li a {
  color: #5b626b !important;
}
.advanced-search-container div.card-body ul li a:hover,
.advanced-search-container div.card-body ul li i[class*="fa"]:hover {
  color: #759fbc !important;
  cursor: pointer !important;
}
.advanced-search-container div.card-body ul li i[class*="fa-trash"] {
  color: red !important;
}
.advanced-search-container .card-header i[class*="fa"] {
  padding: 8px 4px 8px 0px !important;
}
.advanced-search-container div.card-body ul li i + i {
  margin-left: 4px !important;
  padding-left: 4px !important;
}
.advanced-search-container div.dropdown button.dropdown-toggle {
  display: block !important;
  margin-left: 8px !important;
}
.advanced-search-container div.dropdown button.dropdown-toggle,
.advanced-search-container button.btn-info2,
.advanced-search-container div.dropdown div.dropdown-menu {
  width: 100% !important;
}
.advanced-search-container div.dropdown button.dropdown-toggle {
  margin-left: 0px !important;
}
.advanced-search-container div.dropdown-menu.show {
  overflow-y: scroll;
  max-height: 50vh !important;
}
@media screen and (min-width: 320px) {
  .advanced-search-container div.dropdown button.dropdown-toggle {
    margin-left: 8px !important;
  }
}
.advanced-search-container .refresh-shared-search,
.advanced-search-container span.search-title {
  cursor: pointer !important;
}
.navbar .navbar-expand-sm .directory-navbar {
  z-index: 10 !important;
}
.advanced-search-container #myModal {
  z-index: 1111;
}
.advanced-search-container .search.active {
  font-weight: bold;
}

/* .ShareModal .DragHandle {
  cursor: grab;
}

.ShareModal .DragHandle:active {
  cursor: grabbing;
} */

div.inline-toolbar-item h4.page-title-search {
  display: inline-block;
}
div.inline-toolbar-item i {
  margin-left: 8px;
  cursor: pointer;
  font-size: 20px;
}
div.inline-item {
  display: inline;
}
td.header {
  font-size: 16px;
  background-color: #686e82;
  color: white;
}
td.header {
  padding-left: 4px;
}
td.column-name {
  padding-left: 8px;
}
table.column-categories {
  margin-bottom: 0px;
}
table.column-categories tr.hidden,
div.available-fields-container div.available-field.hidden {
  display: none;
}
div.category-header {
  font-size: 16px;
  background-color: #686e82;
  color: white;
  border-radius: 0px;
  padding-left: 4px;
}
.available-fields-container div.card {
  margin-bottom: 0px;
}
.available-fields-container div.card button.btn-link:hover {
  text-decoration: none;
}
.available-fields-container div.card button.btn-link {
  color: white;
  text-decoration: none;
}
div.result-customizer-dialog div.modal-body {
  max-height: 100vh;
  overflow-y: auto;
}
div.result-customizer-dialog div.card-body {
  overflow-y: auto;
}
.available-fields-container div.card-body {
  padding-top: 8px;
  padding-bottom: 8px;
  padding: 0px;
}
.available-fields-container div.card-body div.available-field:not(:last-child) {
  border-bottom: solid 1px #686e82;
}
.available-fields-container div.card-body div.available-field {
  padding: 4px 8px 4px 20px;
}
.available-fields-container div.card-header {
  padding-top: 4px;
  padding-bottom: 4px;
}
.available-fields-container {
  border-left: solid 1px #686e82;
  border-right: solid 1px #686e82;
  border-radius: 4px;
}
.available-fields-container.accordion div.card {
  border-radius: 0px !important;
}
.sortable-items li,
li.sortable-helper {
  background-color: rgba(0, 0, 0, 0.03);
  min-height: 30px;
  border-bottom: white;
  padding: 8px;
  margin-bottom: 4px;
}

li.sortable-helper {
  z-index: 9999;
  list-style: none;
  cursor: move !important;
}
.sortable-item-list {
  cursor: move !important;
}
.sortable-items,
.available-fields {
  max-height: 80vh;
  overflow-y: auto;
}
i[data-field-id] {
  cursor: pointer;
  margin-top: 4px;
}
ul.sortable-item-list i.fa-trash-alt,
li.sortable-helper i.fa-trash-alt {
  color: red;
  margin-top: 4px;
  float: right;
  cursor: pointer !important;
  z-index: 99999;
}
button[disabled] {
  cursor: not-allowed !important;
}

div.saved-search-email-list div.modal-header {
  background-color: #686e82;
  color: white;
}
div.saved-search-email-list div.modal-header button.close {
  color: white;
}
div.saved-search-email-list div.modal-header {
  padding: 8px;
}
div.transparent {
  opacity: 65%;
}
.mainAccordion {
  /* background-color: #ced4da !important ; */
  padding: 0.75rem;
}
.childAccordion {
  border: solid 1px #e5ebf1;
}
/* Advanced filter save search controls ends here*/
.minHeightComms {
  min-height: 18rem;
}

/* Gmail style scrollbar */
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-thumb {
  border-width: 1px 1px 1px 2px;
}
::-webkit-scrollbar-track {
  border-width: 0;
}
::-webkit-scrollbar {
  height: 16px;
  overflow: visible;
  width: 16px;
}
::-webkit-scrollbar-button {
  height: 0;
  width: 0;
}
::-webkit-scrollbar-track {
  background-clip: padding-box;
  border: solid transparent;
  border-width: 0 0 0 4px;
}
::-webkit-scrollbar-track:horizontal {
  border-width: 4px 0 0;
}
::-webkit-scrollbar-track:hover {
  background-color: rgba(220, 172, 0, 0.05);
  box-shadow: inset 1px 0 0 rgba(220, 172, 0, 0.1);
}
::-webkit-scrollbar-track:horizontal:hover {
  box-shadow: inset 0 1px 0 rgba(220, 172, 0, 0.1);
}
::-webkit-scrollbar-track:active {
  background-color: rgba(220, 172, 0, 0.05);
  box-shadow: inset 1px 0 0 rgba(220, 172, 0, 0.14),
    inset -1px 0 0 rgba(220, 172, 0, 0.07);
}
::-webkit-scrollbar-track:horizontal:active {
  box-shadow: inset 0 1px 0 rgba(220, 172, 0, 0.14),
    inset 0 -1px 0 rgba(220, 172, 0, 0.07);
}
::-webkit-scrollbar-thumb {
  background-color: rgba(220, 172, 0, 0.2);
  background-clip: padding-box;
  border: solid transparent;
  border-width: 1px 1px 1px 6px;
  min-height: 28px;
  padding: 100px 0 0;
  box-shadow: inset 1px 1px 0 rgba(220, 172, 0, 0.1),
    inset 0 -1px 0 rgba(220, 172, 0, 0.07);
}
::-webkit-scrollbar-thumb:horizontal {
  border-width: 6px 1px 1px;
  padding: 0 0 0 100px;
  box-shadow: inset 1px 1px 0 rgba(220, 172, 0, 0.1),
    inset -1px 0 0 rgba(220, 172, 0, 0.07);
}
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(220, 172, 0, 0.4);
  box-shadow: inset 1px 1px 1px rgba(220, 172, 0, 0.25);
}
::-webkit-scrollbar-thumb:active {
  background-color: rgba(220, 172, 0, 0.5);
  box-shadow: inset 1px 1px 3px rgba(220, 172, 0, 0.35);
}
::-webkit-scrollbar-track {
  border-width: 0 1px 0 6px;
}
::-webkit-scrollbar-track:horizontal {
  border-width: 6px 0 1px;
}
::-webkit-scrollbar-track:hover {
  background-color: rgba(220, 172, 0, 0.035);
  box-shadow: inset 1px 1px 0 rgba(220, 172, 0, 0.14),
    inset -1px -1px 0 rgba(220, 172, 0, 0.07);
}
::-webkit-scrollbar-thumb {
  border-width: 0 1px 0 6px;
}
::-webkit-scrollbar-thumb:horizontal {
  border-width: 6px 0 1px;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
body::-webkit-scrollbar-track-piece {
  background-clip: padding-box;
  background-color: #f5f5f5;
  border: solid #fff;
  border-width: 0 0 0 3px;
  box-shadow: inset 1px 0 0 rgba(220, 172, 0, 0.14),
    inset -1px 0 0 rgba(220, 172, 0, 0.07);
}
body::-webkit-scrollbar-track-piece:horizontal {
  border-width: 3px 0 0;
  box-shadow: inset 0 1px 0 rgba(220, 172, 0, 0.14),
    inset 0 -1px 0 rgba(220, 172, 0, 0.07);
}
body::-webkit-scrollbar-thumb {
  border-width: 1px 1px 1px 5px;
}
body::-webkit-scrollbar-thumb:horizontal {
  border-width: 5px 1px 1px;
}
body::-webkit-scrollbar-corner {
  background-clip: padding-box;
  background-color: #f5f5f5;
  border: solid #fff;
  border-width: 3px 0 0 3px;
  box-shadow: inset 1px 1px 0 rgba(220, 172, 0, 0.14);
}

.card-noti-link {
  text-decoration: none;
  color: #999; }

.card-noti {
  display: flex;
  border-bottom: 1px solid rgba(153, 153, 153, 0.3);
  justify-content: space-between;
  background-color: #ebf1fa;
  font-size: 12px;
  cursor: pointer !important;
  padding: 6px; }
  .card-noti:hover {
    background-color: #ebedf0; }
  .card-noti .content {
    display: flex;
    color: black;
    align-items: center; }
    .card-noti .content .message {
      margin: 0 8px; }
    .card-noti .content .time {
      color: #999; }
  .card-noti .image {
    margin: 5px; }
    .card-noti .image img {
      height: 40px;
      border-radius: 50%; }
  .card-noti .options {
    visibility: hidden;
    text-align: center;
    font-size: 20px; }
    .card-noti .options .option {
      cursor: pointer;
      opacity: 0.5;
      color: #999; }
      .card-noti .options .option:hover {
        opacity: 1; }
  .card-noti:hover .options {
    visibility: visible; }

.card-noti-read {
  display: flex;
  border-bottom: 1px solid rgba(153, 153, 153, 0.3);
  justify-content: space-between;
  background-color: #ffffff;
  font-size: 12px;
  cursor: pointer !important;
  padding: 6px; }
  .card-noti-read:hover {
    background-color: #ebedf0; }
  .card-noti-read .content {
    display: flex;
    color: black;
    align-items: center; }
    .card-noti-read .content .message {
      margin: 0 8px; }
    .card-noti-read .content .time {
      color: #999; }
  .card-noti-read .image {
    margin: 5px; }
    .card-noti-read .image img {
      height: 40px;
      border-radius: 50%; }
  .card-noti-read .options {
    visibility: hidden;
    text-align: center;
    font-size: 20px; }
    .card-noti-read .options .option {
      cursor: pointer;
      opacity: 0.5;
      color: #999; }
      .card-noti-read .options .option:hover {
        opacity: 1; }
  .card-noti-read:hover .options {
    visibility: visible; }

.spinner_noti {
  height: 32px;
  width: 32px; }

.loaders_noti {
  font-size: 10px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #000000;
  background: linear-gradient(to right, #000000 10%, rgba(0, 0, 0, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
          animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
          transform: translateZ(0); }
  .loaders_noti:before {
    content: "";
    width: 50%;
    height: 50%;
    background: #000000;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0; }

.loaders_noti:after {
  background: #ffffff;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }

@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.notifications .icon {
  position: relative;
  height: 32px;
  width: 29px;
  box-sizing: border-box; }
  .notifications .icon .image {
    height: 24px;
    width: 24px;
    cursor: pointer; }
  .notifications .icon .count {
    height: 17px;
    width: 17px;
    max-width: 17px;
    min-height: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -2px;
    right: 0;
    font-size: 10px;
    border-radius: 50%;
    background-color: red;
    font-weight: 400;
    color: white; }

.notifications .container {
  border: 1px solid rgba(100, 100, 100, 0.4);
  width: 430px;
  font-size: 12px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.25);
  position: absolute;
  z-index: 1;
  background-color: white; }
  .notifications .container .header {
    display: flex;
    justify-content: space-between;
    padding: 8px; }
    .notifications .container .header .header-title {
      color: #1d2129;
      font-weight: bold; }
    .notifications .container .header .header-option {
      margin: 0 5px;
      color: #365899; }
      .notifications .container .header .header-option:hover {
        cursor: pointer;
        text-decoration: underline; }
  .notifications .container .items {
    height: 400px;
    overflow-y: auto; }
    .notifications .container .items .loaders_noti {
      display: flex;
      align-items: center;
      justify-content: center; }
  .notifications .container .empty-notifications {
    display: flex;
    align-items: center;
    justify-content: center;
    height: inherit; }
  .notifications .container .footer {
    text-align: center;
    padding: 5px;
    color: #365899;
    text-transform: capitalize;
    font-weight: bold; }
    .notifications .container .footer .see-all {
      cursor: pointer;
      color: #365899; }

.card-noti p,
.card-noti-read p {
  margin-bottom: 2px;
}
.card-noti p,
.card-noti-read p {
  font-style: oblique;
}
.noti_Request_link {
  color: #7ac2fa !important;
}
.noti_isread {
  color: #1877f2;
}

#sidebar-menu > ul > li > button {
    color: #e6e7e8; 
    display: block; 
    padding: 13px 20px; 
    font-size: 13.3px; 
    position: relative; 
    transition: all 0.5s; 
}
#sidebar-menu > ul > li > a {
    color: #e6e7e8;
}
#sidebar-menu > ul > li > a.active {
    color: #ffffff !important;
    background-color: #cb2630;
}
a.logo {
    color: #ffffff;
}

.MuiAutocomplete-paper{
    max-height: 200px !important;
    overflow-y: auto !important;
}

.MuiFormLabel-root.Mui-focused{
color:#4285f4 !important;
font-size: 1.1rem !important;
}
.MuiInput-underline:after {
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    position: absolute;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    transition: -webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, -webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    border-bottom: 2px solid #4285f4;
    pointer-events: none;
}
.MuiInputLabel-formControl {
    top: 0;
    left: 0;
    position: absolute;
    -webkit-transform: translate(0, 24px) scale(1);
            transform: translate(0, 24px) scale(1);
    color: #979797 !important;
    font-weight: normal !important;
}
.content > .team-container,
.team-container {
  margin-top: 110px !important;
}

@media (max-width: 1024px) {
  .content > .team-container,
  .team-container {
    margin-top: 145px !important;
  }
}

@media (max-width: 527px) {
  .content > .team-container,
  .team-container {
    padding: 5px !important;
    margin-top: 160px !important;
  }
}

.content > .team-container .react-bootstrap-table .reset-expansion-style {
  padding: 0;
}

.content > .team-container .react-bootstrap-table .row-expansion-style {
  padding: 8px;
}

.content > .team-container .react-bootstrap-table .row-expand-slide-appear {
  max-height: 0;
  overflow: hidden;
}

.content
  > .team-container
  .react-bootstrap-table
  .row-expand-slide-appear-active {
  max-height: 1000px;
  transition: max-height 3s linear;
}

.content > .team-container .react-bootstrap-table .row-expand-slide-exit {
  max-height: 1000px;
}

.content .team-style .text-primary {
  color: #759fbc !important;
}

.content
  > .team-container
  .react-bootstrap-table
  .row-expand-slide-exit-active {
  max-height: 0;
  overflow: hidden;
  transition: max-height 400ms cubic-bezier(0, 0.95, 0, 0.95);
}

.content > .team-container .rpl-Loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  z-index: 10000;
}

.lds-ripple {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 3;
  width: 64px;
  height: 64px;
}

.lds-ripple div {
  margin: auto;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  position: absolute;
  border: 4px solid #b0fffa;
  opacity: 1;
  border-radius: 50%;
  -webkit-animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
          animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}

@-webkit-keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}

@keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}

.perm-btn.lds-ripple {
  position: relative;
  display: inline-block;
  margin: auto;
  z-index: 3;
  width: 15px;
  height: 15px;
  margin-bottom: -2px;
}

.perm-btn.lds-ripple div {
  border: 2px solid #b0fffa;
  -webkit-animation: btn-lds-ripple 0.5s cubic-bezier(0, 0.2, 0.8, 1) infinite;
          animation: btn-lds-ripple 0.5s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.perm-btn.lds-ripple div:nth-child(2) {
  border: 2px solid #b0fffa;
  -webkit-animation: btn-lds-ripple 0.6s cubic-bezier(0, 0.2, 0.8, 1) infinite;
          animation: btn-lds-ripple 0.6s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

@-webkit-keyframes btn-lds-ripple {
  0% {
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    width: 16px;
    height: 16px;
    opacity: 0;
  }
}

@keyframes btn-lds-ripple {
  0% {
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    width: 16px;
    height: 16px;
    opacity: 0;
  }
}

.bold-input {
  font-size: 25px;
  font-weight: bold;
}

.bold-input::-webkit-input-placeholder {
  color: #c0c0c0;
  font-style: italic;
}

.scopelist {
  max-height: 380px;
  overflow-y: scroll;
}

/* .permissionsList {
    max-height: 400px;
    overflow-y: scroll;
} */

.round-check{
  position: relative;
}

.round-check label, .ST-check {
  background-color: #282828;
  box-shadow: inset rgba(0, 0, 0, 0.75) 0px -1px 2px;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 18px;
  left: 0;
  right: 0;
  margin: 0 auto;
  position: absolute;
  top: 0;
  border: 1px solid white;
  width: 18px;
  transition: all 0.5s;
  -o-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

    .round-check.uncheck-light label, .ST-check.check-light {
        background-color: #c0c0c0;
    }

.round-check.uncheck-default label {
  background-color: #b3e4ff;
  border: 0px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #555590 0px -1px 2px,
    #b2e4ff 1px 1px 6px 1px;
  transition: all 0.5s;
  -o-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

    .round-check.uncheck-danger label, .ST-check.check-danger {
        background-color: #ff738b;
        border: 0px;
        box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #b51d65f7 0px -1px 2px, #f3c9b0 1px 1px 6px 1px;
        transition: all 0.5s;
        -o-transition: all 0.5s;
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
    }

.round-check input[type="checkbox"] {
  visibility: hidden;
}

    .round-check input[type="checkbox"]:checked + label, .ST-check.check-success {
        background-color: #b4f8bc;
        /* background-color: #ABFF00; */
        border: 0px;
        box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #83b51df7 0px -1px 2px, #70ff08 1px 1px 6px 1px;
        transition: all 0.5s;
        -o-transition: all 0.5s;
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
    }
    .round-check.uncheck-default label, .ST-check.check-default,
    .round-check.checked-default input[type="checkbox"]:checked + label {
        background-color: #b3e4ff;
        border: 0px;
        box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #555590 0px -1px 2px, #b2e4ff 1px 1px 6px 1px;
        transition: all 0.5s;
        -o-transition: all 0.5s;
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
    }

    .round-check input[data-child-check="true"] + label, .ST-check[data-child-check="true"] {
        border-radius: 0;
    }
/*.round-check input[data-checked='true'][data-disabled='true'] + label {
        box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, #83b51df7 0px -1px 2px, #70ff08 1px 1px 6px 1px;
        transition: all .5s;
        -o-transition: all .5s;
        -webkit-transition: all .5s;
        -moz-transition: all .5s;
    }*/
.round-check.check-success input[type="checkbox"]:checked + label {
  background-color: #02a499;
}

.round-check input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
/*Special team classes are also used in round-check css*/
/*Special team Checks*/
.ST-check {
    position: relative;
}

    .ST-check.check-small {
        width: 12px;
        height: 12px;
    }





    #MemberlistScroll div.active,
    #TeamList li.active {
        background-color: #38a4f8 !important;
        color: #ffffff !important;
    }

#MemberlistScroll,
#TeamList,
#deleteChildTeamList {
  max-height: 300px;
  overflow-y: scroll;
}

.perm-row {
  height: 40px;
  transition: all 0.5s;
  -o-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

.scopelist .perm-row {
  height: 40px;
  transition: all 0.5s;
  -o-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

.perm-col-1 {
  width: 60%;
}

.perm-col-2 {
  width: 10%;
}

.perm-row.perm-row-hide {
  height: 0;
  overflow: hidden;
  transition: all 0.5s;
  -o-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

.scopelist .perm-row-hide {
  display: none;
}

.scope-colaps {
  height: 30px;
  text-align: right;
  float: right;
  width: 97%;
  position: relative;
  top: -27px;
}

@media (max-width: 407px) {
  .btn-x-sm {
    min-width: 80px;
  }
}

@media (max-width: 527px) {
  .table {
    width: auto;
    min-width: 100%;
  }
}

.permissionsList th.text-center {
  width: 10%;
}

.opacity-half {
  opacity: 0.5 !important;
}

.opacity-quater {
  opacity: 0.25 !important;
}

.assign-member {
  top: -2px;
  right: -50%;
  left: -50%;
  margin: 0 auto;
}

.bg-perm-info {
  background-color: #78b5df !important;
}

@media (max-width: 991px) {
  .assign-member {
    top: unset;
    bottom: 0;
  }
}

.permissionTablePagination .react-bootstrap-table-pagination {
  margin-left: 0;
  margin-right: 0;
}

#DeleteModalTeams .bullets {
  width: 7px;
  height: 7px;
  border: 2px solid #ccc;
  margin: auto;
  left: 3px;
  top: 0;
  bottom: -2px;
  position: absolute;
  border-radius: 50%;
}

#defaulPermissionBody {
  overflow-y: unset !important;
}

#defaulPermissionTab .nav-link.active {
  background-color: #759fbc !important;
  color: #ffffff !important;
  border-bottom: 1px solid #dee2e6 !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.positionList td {
  padding: 0px 15px !important;
}

.positionList table.table.table-bordered h4 {
  margin: 5px 0;
}

#AssignMembers .close {
  color: white !important;
}

.styled-modal .modal-title {
  text-transform: uppercase !important;
  color: white;
  margin-top: 0px !important;
}

.styled-modal .modal-header button.close {
  color: white !important;
}

#MemberlistScroll .active {
  background-color: #38a4f8 !important;
  color: #ffffff !important;
}
#TeamlistScroll .active {
  background-color: #38a4f8 !important;
  color: #ffffff !important;
}

.changeColor {
  color: #759fbc !important;
}

.team-menu .dropdown-item {
  padding: 0.25rem 0.25rem !important;
}

#pageDropDown {
  margin-top: -2px;
  padding: 1px 8px;
}
/*.members-pagination ul.pagination{
   margin-bottom:3px!important
}*/
.positionList table .reset-expansion-style,
.positionList table .row-expansion-style {
  padding: 0 !important;
}
.positionList table .reset-expansion-style table td {
  padding: 0 !important;
}
#childTeamList {
  max-height: 300px;
  overflow-y: auto;
}
/*-------[Bulk Team Progress Bar]-----------*/
#bulkTeam-PB.progress {
  height: 7px;
  overflow: visible;
  margin-bottom: 34px;
}
/*bar setting*/
#bulkTeam-PB.progress .progress-bar {
  height: 7px;
  position: relative;
}
/*label setting*/
#bulkTeam-PB.progress .progress-label {
  position: absolute;
  padding: 2px 6px;
  right: -17px;
  width: 39px;
  background-color: #1e2629;
  bottom: -33px;
}
/*label arrow setting*/
#bulkTeam-PB.progress .progress-label:after {
  content: "";
  max-width: 0;
  height: 0;
  left: 0;
  top: -11px;
  right: 0;
  margin: 0 auto;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 14px solid black;
  position: absolute;
}
/*-------[End Of Progress Bar]-----------*/

.borderedSection_mainContainer__ECAHZ {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  border-left: 1px solid #b2b2b2;
  border-bottom: 1px solid #b2b2b2;
  border-right: 1px solid #b2b2b2;
  border-radius: 5px;
  margin: 1em; }
  .borderedSection_mainContainer__ECAHZ .borderedSection_childrenContainer__1lD95 {
    padding: 1em; }
  .borderedSection_mainContainer__ECAHZ .borderedSection_header__4e6KU {
    display: flex;
    flex-direction: row;
    width: 100% !important; }
    .borderedSection_mainContainer__ECAHZ .borderedSection_header__4e6KU .borderedSection_headerBorderBefore__1_QFk {
      border-top: 1px solid #b2b2b2;
      width: 1em;
      border-top-left-radius: 5px; }
    .borderedSection_mainContainer__ECAHZ .borderedSection_header__4e6KU .borderedSection_headerTitle__3O3oU {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      grid-gap: 0.25em;
      gap: 0.25em;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      height: 2em;
      margin: -1em 0.5em 0em 0.5em;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 1em;
      font-weight: 600; }
    .borderedSection_mainContainer__ECAHZ .borderedSection_header__4e6KU .borderedSection_headerBorderAfter__1QE6o {
      border-top: 1px solid #b2b2b2;
      width: 1em;
      flex-grow: 2;
      border-top-right-radius: 5px; }

.permission-nav {
    margin-bottom: 15px;
    background-color: #ececf1 !important;
    padding-top: 0;
    padding-bottom: 0;
    position: fixed;
    top: 70px;
    width: 100%;
    z-index: 1;
}
 
@media(max-width:1024px){
    .permission-nav {
        top:116px;
    }
}
.p-tabs{
    padding: 7px 4px;
    
}
@media(min-width:537px){
    .p-tabs{
        font-size:15px;
        margin-right:17px
    }
}
    .p-tabs.active {
        color: #759FBC !important;
        border-bottom: 3px solid #759FBC !important;
    
    }

.w12-5 {
  width: 12.5%;
}

.w17-5 {
  width: 17.5%;
}

.w6 {
    width: 6%;
}

.w15 {
    width: 15%;
}

.import-table .w17-5,
.import-table .w6,
.import-table .w15,
.import-table .w12-5 {
    display: inline-block;
}

.import-table .top-th.dark-gray {
  border: 1px solid #4f4e4e;
  background: #4f4e4e;
  padding-right: 14px;
}

.import-table .dark-gray .td {
  color: white;
  font-weight: 500;
}

.top-th.red-white {
  background: #ff0c0c;
  color: white;
  font-weight: 500;
  cursor: pointer;
  z-index: 200;
}

.top-th.yellow-black {
  background: #ffe500 !important;
  font-weight: 500;
  color: #3f3b3b;
  cursor: pointer;
  z-index: 200;
  position: relative;
}

.icons-file i {
  z-index: 300;
  position: relative;
  font-size: 18px;
  margin-top: 1px;
}

.top-th.light-gray {
  background: #5e5e5e;
  color: #f6f6f6;
  font-weight: 500;
  cursor: pointer;
  z-index: 200;
  position: relative;
}

.w93 {
  width: 95%;
  display: inline-block;
}

.w7 {
  display: inline-block;
  width: 5%;
  white-space: nowrap;
}
.w7.icons-file {
  text-align: right;
  padding-right: 2px;
}
.w7.icons-file a.text-white,
.w7.icons-file .text-dark {
  padding: 0 1.5px;
}
.import-table .td {
  padding: 0px 10px;
}

.light-white {
  cursor: pointer;
}

.import-table .light-white .td {
  border-right: 1.5px solid #938888;
  font-size: 12px;
  font-weight: 400;
  color: #6a6464;
}

.import-table .light-white .td:last-child {
  border-right: 0px solid #938888;
}

.top-td.light-white {
  border-left: 1px solid #938888;
  border-right: 1px solid #938888;
  border-bottom: 1px solid #938888;
  display: flex;
  flex-wrap: wrap;
}

.top-th.yellow-black,
.red-white,
.light-gray {
  border-right: 1px solid #938888;
  border-left: 1px solid gray;
  border-bottom: 1px solid #938888;
}

.w80 {
  display: inline-block;
  width: 80%;
}

.w20 {
  display: inline-block;

  width: 20%;
}

.yellow-black .td {
  font-weight: 600;
  color: #575555;
}

.w20.icons-file {
  text-align: right;
  padding-right: 10px;
  font-size: 17px;
}

.w20.icons-file i {
  margin: 0 2px;
  cursor: pointer;
}

div.scroll-view {
  max-height: 350px;
  overflow: auto;
  overflow-x: hidden;
}

.w100 {
  width: 100%;
}

.rowdiv {
  display: flex;
  flex-wrap: wrap;
}

.width35 {
  width: 35%;
  flex: 0 0 35%;
}

p.infoError {
  margin: 0;
  font-size: 12px;
  font-weight: 500;
}

h6.error-i {
  padding-left: 45px;
  font-size: 14px;
  color: #ff0c0c;
}

h6.error-i .fa-pen {
  color: #4f4e4e;
  font-size: 12px;
  margin: 0 2px;
}

h6.error-i i {
  font-size: 12px;
}

.pdl-45 {
  padding-left: 45px;
}

p.infoError span {
  font-weight: 200;
  color: #696969;
}

.bdr-bottom {
  border-bottom: 1px solid #938888;
}

h6.ignore-i {
  font-size: 14px;
}

.ignore-i i {
  font-size: 12px;
  margin: 0 2px;
}

.ignore-i .fa-trash {
  color: #ff0c0c;
}

.width25 {
  width: 25%;
  flex: 0 0 25%;
}
.width40 {
  width: 40%;
  flex: 0 0 40%;
}
.width20 {
  width: 20%;
  flex: 0 0 20%;
}
.width15 {
  width: 15%;
  flex: 0 0 15%;
}
.tablehead {
  display: flex;
  flex-wrap: wrap;
  background: #5e5e5e;
  color: white;
  padding: 1px 8px;
  font-size: 12px;
  border: 1px solid #c5c0c0;
}

.tr-body {
  display: flex;
  flex-wrap: wrap;
  padding: 0 8px;
  font-size: 12px;
  border-right: 1px solid gray;
  border-left: 1px solid gray;
  border-bottom: 1px solid gray;
}

.tr-body .td {
  border-right: 1px solid gray;
}

.tr-body .td:last-child {
  border-right: 0px solid gray;
}

.tr-body .bold-dark {
  font-weight: 500;
}

@media (max-width: 785px) {
  .import-table .dark-gray .td {
    white-space: nowrap;
  }

  .import-table {
    overflow-x: scroll;
  }

  .top-th.dark-gray {
    display: table-row;
    white-space: nowrap;
  }

  .top-th.dark-gray .td {
    display: table-cell;
    white-space: nowrap;
  }

  .top-td.light-white .td {
    white-space: initial;
  }

  .top-th.red-white,
  .top-th.yellow-black,
  .top-th.light-gray {
    min-width: 496px;
  }

  .top-td.light-white {
    min-width: 496px;
  }

  .top-td.light-white .td {
    display: table-cell;
  }

  .top-td.light-white .w17-5.td {
    width: 98px;
  }

  div.scroll-view {
    max-height: 190px;
    overflow: auto;
    overflow-x: hidden;
    min-width: 496px;
  }

  .resW99 {
    width: 99px !important;
  }

  .resW99 {
    width: 99px !important;
  }

  .resW72 {
    width: 72px !important;
  }

  .resW65 {
    width: 65px !important;
  }

  .resW85 {
    width: 85px !important;
  }

  .resW31person {
    width: 31% !important;
    flex: 0 0 31%;
  }

  .resW69person {
    width: 69% !important;
    flex: 0 0 69%;
  }

  .respdl-20 {
    padding-left: 20px !important;
  }
}

@media (max-width: 625px) {
  .import-table .dark-gray .td {
    white-space: nowrap;
  }

  .import-table {
    overflow-x: scroll;
  }
}

.errorignoremodel {
  max-width: 900px;
}

.ignore-gray {
  padding: 9px 8px;
  border: 1px solid #b3b3b3;
  background: #c1c2c2;
  cursor: pointer;
}

.ignore-gray h5 {
  margin: 0;
  color: #494949;
  font-size: 16px;
}

select.select-ignore {
  width: 100%;
  border-radius: 100px;
  padding: 0.48rem 0.95rem;
  border: 1px solid #ced4da;
  color: gray;
}

select.select-ignore option {
  padding: 0px 10px !important;
}

.modalinput label {
  margin-bottom: 2px;
  margin-left: 9px;
}

.ignore-table-Change thead.bg-dark th {
  padding: 2px 10px;
  color: white;
  font-size: 13px;
}

.ignore-table-Change td {
  padding: 1px 11px;
  border-right: 1px solid #aeaeae;
}

.ignore-table-Change td:last-child {
  border-right: 0px solid;
  border-left: 0 solid;
  border-bottom: 0 solid;
}

.ignore-table-Change td {
  padding: 3px 11px;
  border-bottom: 1px solid #aeaeae;
  border-right: 1px solid #aeaeae;
}

.ignore-table-Change thead tr {
  border: 1px solid gray;
}

.ignore-table-Change td:first-child {
  border-left: 1px solid #aeaeae;
}

.ignore-delete-edit {
  white-space: nowrap;
}

.ignore-delete-edit .fa-trash {
  color: #ff1919;
  padding-left: 5px;
}

.ignore-delete-edit i {
  padding: 0 0;
  font-size: 12px;
  cursor: pointer;
}

.ignore-table-Change input.form-control {
  font-size: 13px;
}

.plr-2 {
  /* padding: 0 8px; */
  padding: 0.25rem 1.5rem;
}
span.sectionCount {
  font-weight: 400;
  font-size: 11px;
  margin-left: 15px;
  font-style: italic;
  position: relative;
  top: -1px;
}
.payfiledetail h4 {
  font-size: 14px;
  margin: 0;
  text-align: left;
}
.payfiledetail p {
  text-align: left;
  font-size: 18px;
  margin: 0;
  padding: 0;
}
.payfiledetail {
  position: relative;
  top: 21px;
}
.top-th {
  padding: 2px 0;
}
.reason-area {
  font-size: 15px !important;
}

/*
    EditUserStyle Sheet
    Author: Amir I.

*/
.content > .member-container-fluid {
  padding-left: 15px !important;
  padding-right: 15px !important;
  margin-top: 35px;
}
.content > .member-container-fluid input::-webkit-input-placeholder {
  color: #c0c0c0;
  font-style: italic;
}
.content > .member-container-fluid .md-form .prefix,.small-prefix .prefix {
  font-size: 14px!important;
  position: absolute;
  top: 5px;
  color: #a4a1a1;
}
    .content > .member-container-fluid .md-form .prefix ~ input,
    
    .content > .member-container-fluid .md-form .prefix ~ textarea
    {
        margin-left: 15px;
        padding-left: 15px;
        width: 80%;
    }

@media (max-width: 1040px) {
  .content > .member-container-fluid {
    margin-top: 130px;
  }
}

@media (max-width: 527px) {
  .content > .member-container-fluid {
    padding: 5px !important;
    margin-top: 180px;
  }
}
/* @media(max-width:420px) {
    
    .d-tabs {
        margin-right: 0px !important;
        display: unset;
        font-size: 14px !important;
        padding: 3px !important;
    }
} */
@media (min-width: 350px) and (max-width: 488px) {
  .d-tabs {
    margin-right: 0px !important;
    display: unset;
    font-size: 12px !important;
    padding: 3px !important;
  }
}

.modal-max-width {
  max-width: 600px !important;
}
.jobcarousel .carousel-indicators {
  margin-bottom: 5px;
}
.jobcarousel .carousel-indicators li {
  background-color: rgba(0, 0, 0, 0.7);
}
.jobcarousel .carousel-control-prev,
.jobcarousel .carousel-control-next {
  color: black !important;
  width: 15px !important;
  top: unset;
  height: 50%;
  align-items: start;
  -ms-flex-align: start;
}
.jobcarousel .carousel-control-prev {
  margin-left: 12px;
}
.jobcarousel .carousel-control-next {
  margin-right: 12px;
}
.jobcarousel .carousel-control-prev .carousel-control-prev-icon,
.jobcarousel .carousel-control-next .carousel-control-next-icon {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -0.0667em;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  width: unset !important;
  height: unset !important;
  background: unset !important;
  margin-top: 15px;
}
.jobcarousel .carousel-control-next .carousel-control-next-icon:before {
  content: "\F054";
}
.jobcarousel .carousel-control-prev .carousel-control-prev-icon:before {
  content: "\F053";
}

.c-pointer {
  cursor: pointer !important;
}
.password-icon-position {
  position: absolute;
  right: 0;
  top: 5px;
}

.custom-control-label-new {
  /* font-size: 15px;
            letter-spacing: 0.5px; */
  line-height: 28px;
  margin-bottom: 0;
}

.tickIcon {
  margin-right: 8px;
  margin-left: -6px;
  color: green;
}
.crossIcon {
  margin-right: 9.5px;
  margin-left: -3px;
  color: red;
}

.facility-filter .dropdown-menu-body.dropdown-menu.show {
  padding: 10px 0 !important;
  margin: 0 !important;
}
.facility-filter .ffi {
  font-size: 16px !important;
  margin-left: 0 !important;
}

.jobhistory-datepicker {
    display: block;
    width: 79px!important;
}
.addressMemberForm #state {
    padding: 10px 0px 9px;
    margin-bottom: 0 !important;
    border-bottom: 0 !important;
    box-shadow: unset;
}
    .addressMemberForm #state.is-invalid, .was-validated #state:invalid, #state.is-valid, .was-validated #state:valid{
        background-position-x:right
    }
    .addressMemberForm .state-container .MuiInput-underline::after {
        border-bottom-width: 1px
    }
.addressMemberForm .state-container .MuiInput-underline {
    margin-top: 1.5rem;
}
.addressMemberForm #state-label {
    line-height:inherit;
}
.addressMemberForm #state-helper-text {
    margin-top: 6px;
    font-size: 11px;
}
.addressValidUSPS{
  flex-direction: column;
  align-content: space-around;
  flex-wrap: wrap;
}
.spinnerCard {
  position: absolute;
  top: 50%;
  left: 50%;
  background-size: 100%;
}

.spinnerFacilityCategory {
  align-self: center !important;
  justify-content: center !important;
  padding: 4rem !important;
}

.h1tag,
.h2tag,
.h3tag,
.h4tag,
.h5tag,
.h6tag {
  margin: 3px 0 !important;
  font-family: "Sarabun", sans-serif;
  font-weight: 600;
}
.text-primary-card {
  color: #759fbc !important;
  font-family: "Sarabun", sans-serif;
  font-weight: 600;
  font-size: 1rem;
}
.facilityTabSection {
  width: 100%;
  border-radius: 0;
  padding: 11px 20px;
  background: #495057;
  text-align: left;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.9px;
  border: none;
  transition: background-color 0.5s ease;
  color: white;
  cursor: pointer;
  margin: 1px !important;
  vertical-align: middle;
  justify-content: space-between;
  display: flex;
}
.facilityCollapsBtn {
  width: 100%;
  border-radius: 0;
  padding: 11px 20px;
  text-align: left;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.9px;
  border: none;
  transition: background-color 0.2s ease;
  color: white;
  cursor: pointer;
  background: #6c757d;
  vertical-align: middle;
  justify-content: space-between;
  display: flex;
}

.header_1tk9zku {
  float: right;
}

.customDelete {
  outline: none;
  border: 0;
  box-shadow: none;
  background-color: transparent;
  color: white;
}
.container_9tizg4 {
  z-index: 1050 !important;
}
.scrollWidthMapDiv {
  width: 50rem;
}
.modalshadow {
  background: #f8f9fa;
}
body.modal-open {
  overflow-y: hidden;
  position: fixed;
  right: 1.5vh !important;
  left: 0;
  padding-right: 0 !important;
  top: 0;
  bottom: 0;
}

/* body {
  height: 200vh;
  overflow-y: hidden; 
  padding-right: 15px; 
} */

.accordianContainerBorder {
  border: #dee2e6 1px solid !important;
}
.facilityCategoriesList > div > .collapse {
  border: #dee2e6 1px solid !important;
}
.parentLogsDiv {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-right: 0.2vw;
  padding-left: 0.2vw;
}
.facilityAccordianHeight {
  min-height: 20vh;
}

.mapsHeader {
  padding: 1.25rem;
  flex: 1 1 auto;
}

.cursorPointer {
  cursor: pointer !important;
}

.radius-2px {
  border-radius: 2px !important;
}

.radius-2px > .switch-group > .btn-light {
  border-radius: 2px !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.info-icon {
  color: #759fbc !important;
}

.smallNceptLabel {
  font-size: 60%;
  vertical-align: super;
}

.dropped {
  background-color: #ff3d00 !important;
}

.grayBackground {
  background-color: #b6b6b6 !important;
}

.raised {
  background-color: #53b096 !important;
}

.darkLabel {
  color: #9fa9a6 !important;
}

.chartIconsGreen {
  color: #53b096 !important;
}

.cardShadow {
  box-shadow: 3px 6px lightgrey;
}

.cardHover:hover {
  /* color: #759FBC !important; */
  cursor: pointer !important;
  box-shadow: 5px 8px darkgrey;
}

.chartIconhover:hover {
  /* color: #759FBC !important; */
  cursor: pointer !important;
  font-size: 20px;
}

.scrollSettings {
  white-space: nowrap;
  overflow-x: auto;
}

.marginAdjust {
  margin-top: 0px;
}

@media (max-width: 1024px) {
  .marginAdjust {
    margin-top: -128px;
  }
}
@media (min-width: 280px) and (max-width: 515px) {
  .marginAdjust {
    margin-top: 6px;
  }
}

#yearBtn:disabled {
  background-color: darkgrey;
}

#allTimeBtn:disabled {
  background-color: darkgrey;
}

#LogslistScroll {
  max-height: 300px;
  overflow-y: scroll;
  min-height: 7rem;
}
#EmailLogginglistScroll {
  max-height: 300px;
  overflow-y: scroll;
  min-height: 7rem;
}
.loggingLoading {
  position: relative;
  left: 50%;
  margin-left: -50px;
  margin-bottom: 2%;
}
.mainLoader {
  position: relative;
  left: 50%;
  margin-left: -50px;
  margin-bottom: 2%;
}
.popOverCol {
  text-align: right;
}
.childTextFields {
  padding-left: 1.6rem;
}

.content>.pac-container,
.pac-container {
  margin-top: 110px !important;
}

@media (max-width: 1024px) {

  .content>.pac-container,
  .pac-container {
    margin-top: 145px !important;
  }
}

@media (max-width: 527px) {

  .content>.pac-container,
  .pac-container {
    padding: 5px !important;
    margin-top: 160px !important;
  }
}

.content>.pac-container .bg-light-blue {
  background-color: rgba(186, 236, 255, 0.59);
}

.pac-form input::-webkit-input-placeholder {
  color: #ccc !important;
  font-style: italic;
}

.pac-form input:focus+label,
.pac-form textarea:focus+label {
  color: #4285f4;
}

.pac-form .md-form .prefix {
  position: absolute;
}

#flyer-uploader .filepond--root {
  overflow-y: hidden !important;
}

.candidateActionSpan:hover {
  cursor: pointer;
}

.historyheight {
  max-height: 200px !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  word-wrap: break-word !important;
}

/* textarea:-moz-placeholder, */
/* Firefox 18- */
/* textarea::-moz-placeholder, */
/* Firefox 19+ */
/* textarea:-ms-input-placeholder, */
/* IE 10+ */
/* textarea::-webkit-input-placeholder, */
/* Webkit based */
/* textarea::placeholder  */

.textareaPlaceholder::-webkit-input-placeholder {
  /* Modern browsers */
  color: #e6e6e6;
  font-style: italic;
}

.textareaPlaceholder::placeholder {
  /* Modern browsers */
  color: #e6e6e6;
  font-style: italic;
}
.equipment-control::-webkit-input-placeholder {
  font-style: italic;
  font-size: smaller;
  color: #d6d6d6;
}
.equipment-control::-moz-placeholder {
  font-style: italic;
  font-size: smaller;
  color: #d6d6d6;
}

input[id*="equipment"]::-webkit-input-placeholder {
  font-style: italic;
  font-size: small;
}
input[id*="equipment"]::-moz-placeholder {
  font-style: italic;
  font-size: small;
}

.text_ctrl .MuiInputBase-root ::-webkit-input-placeholder {
  font-style: italic;
  font-size: small;
  font-weight: lighter;
}
.text_ctrl .MuiInputBase-root ::-moz-placeholder {
  font-style: italic;
  font-size: small !important;
  font-weight: lighter !important;
}

.text_ctrl label {
  font-style: italic;
  font-size: smaller !important;
}
.text_ctrl ::-webkit-input-placeholder {
  color: #d6d6d6;
  font-size: smaller !important;
}
.text_ctrl ::-moz-placeholder {
  color: #d6d6d6;
  font-size: smaller !important;
}
.fedollaramt .fa-dollar-sign {
  padding-left: 1.1rem;
}
#depreciationTbl > thead > tr > th {
  font-style: italic;
  font-weight: bold;
  background: gainsboro;
}

#addEquipmentForm .table-responsive {
  min-height: 6rem !important;
  text-align: center;
}

.dateCtrl {
  position: relative;
  bottom: 0.7rem;
}
.equipmentUser {
  display: inline-block;
  min-width: 6rem;
}
.actionIcons {
  display: inline;
}
.AttachmentsHeader > .modal-title {
  width: 100%;
}

#addEquipmentForm > .actionBtns button {
  font-style: italic;
}
.feActions {
  background-color: #c8cdd1;
}
.minHeightSidebar {
  min-height: 33rem;
}
span.input-range__label-container {
  font-size: 9px;
  color: black;
  font-family: inherit;
  letter-spacing: 1.2px;
}

/*########################## FE Header CSS ##################################### */

.equipmentHeader-navbar-brand:hover {
  color: #759fbc !important;
  cursor: pointer !important;
}

.equipmentHeader-navbar-brand:active {
  color: #759fbc !important;
}

.equipmentHeader-navbar-brand-active {
  border-bottom: 3px solid #759fbc !important;
}

.equipmentHeader-navbar-brand button {
  cursor: pointer !important;
}
@media (max-width: 1024px) {
  .equipmentHeader-navbar {
    margin-top: 50px !important;
  }
}
.equipmentHeader-navbar {
  /* border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom: 1px solid darkgrey;
    border-right: 1px solid darkgrey;
    border-left: 1px solid darkgrey; */
  margin-bottom: 15px;
  background-color: #ececf1 !important;
  flex-wrap: nowrap;
  /* padding-top: 0;
  padding-bottom: 0; */
  position: fixed;
  top: 70px;
  width: 100%;
  z-index: 1;
}
.equipmentHeader-navbar-brand {
  font-size: 0.85rem !important;
}

@media (min-width: 343px) and (max-width: 488px) {
  .equipmentHeader-navbar-brand {
    font-size: calc(13px + 10 * ((100vw - 769px) / 2048)) !important;
    /* display: contents !important; */
  }

  .navbar-brand .equipmentHeader-navbar-brand {
    font-size: calc(18px + 10 * ((100vw - 769px) / 2048)) !important;
  }

  .equipmentHeader-navbar-brand:hover {
    color: #759fbc !important;
    cursor: pointer !important;
  }

  .equipmentHeader-navbar-brand:active {
    color: #759fbc !important;
  }

  .equipmentHeader-navbar-brand-active {
    border-bottom: 3px solid #759fbc !important;
  }

  .equipmentHeader-navbar-brand button {
    cursor: pointer !important;
  }

  .equipmentHeader-navbar + .container-fluid {
    /* margin-top: 100px; */
    margin-top: 6%;
  }

  @media (min-width: 200px) and (max-width: 299px) {
    .equipmentHeader-navbar + .container-fluid {
      margin-top: 16%;
    }
  }

  @media (min-width: 300px) and (max-width: 399px) {
    .equipmentHeader-navbar + .container-fluid {
      margin-top: 16%;
    }
  }

  @media screen and (min-width: 400px) and (max-width: 499px) {
    .equipmentHeader-navbar + .container-fluid {
      margin-top: 8%;
    }
  }

  @media (min-width: 500px) and (max-width: 599px) {
    .equipmentHeader-navbar + .container-fluid {
      margin-top: 16%;
    }
  }

  @media (min-width: 600px) and (max-width: 699px) {
    .equipmentHeader-navbar + .container-fluid {
      margin-top: 11%;
    }
  }

  @media (min-width: 700px) and (max-width: 799px) {
    .equipmentHeader-navbar + .container-fluid {
      margin-top: 8%;
    }
  }

  @media (min-width: 800px) and (max-width: 999px) {
    .equipmentHeader-navbar + .container-fluid {
      margin-top: 6%;
    }
  }

  @media (min-width: 1000px) and (max-width: 1199px) {
    .equipmentHeader-navbar + .container-fluid {
      margin-top: 11%;
    }
  }

  @media (min-width: 1200px) and (max-width: 1299px) {
    .equipmentHeader-navbar + .container-fluid {
      margin-top: 9%;
    }
  }

  @media (min-width: 1300px) and (max-width: 1399px) {
    .equipmentHeader-navbar + .container-fluid {
      margin-top: 9%;
    }
  }

  @media (min-width: 1400px) and (max-width: 1500px) {
    .equipmentHeader-navbar + .container-fluid {
      margin-top: 8%;
    }
  }

  @media (min-width: 1500px) and (max-width: 1600px) {
    .equipmentHeader-navbar + .container-fluid {
      margin-top: 7%;
    }
  }
}

@media (min-width: 489px) and (max-width: 564px) {
  .feHeader-tabs {
    margin-right: 0px !important;
    display: unset;
    font-size: 0.85rem !important;
    padding: 3px 8px 3px 3px !important;
  }
  .feHeaderCogIcon .fa-cog {
    font-size: 0.95rem !important;
    padding: 1px 6px 1px 1px !important;
  }
  .feHeaderCogIcon > .dropdown-menu-body {
    padding: 1px !important;
    font-size: smaller;
    top: 0.6rem !important;
  }
  .feHeaderCogIcon a {
    padding: 2px !important;
  }
  .equipmentHeader-navbar {
    padding-left: 4px;
    padding-right: 4px;
  }
  .cnrbtn {
    padding: 3px 7px 3px 7px !important;
  }
}

@media (min-width: 377px) and (max-width: 488px) {
  .feHeader-tabs {
    margin-right: 0px !important;
    display: unset;
    font-size: 0.65rem !important;
    padding: 3px 8px 3px 3px !important;
  }
  .feHeaderCogIcon .fa-cog {
    font-size: 0.65rem !important;
    padding: 1px 6px 1px 1px !important;
  }
  .feHeaderCogIcon > .dropdown-menu-body {
    padding: 1px !important;
    font-size: smaller;
    top: 0.6rem !important;
  }
  .feHeaderCogIcon a {
    padding: 2px !important;
  }
  .equipmentHeader-navbar {
    padding-left: 4px;
    padding-right: 4px;
  }
  .cnrbtn {
    padding: 3px 6px 3px 6px !important;
  }
}

@media (min-width: 343px) and (max-width: 376px) {
  .feHeader-tabs {
    margin-right: 0px !important;
    display: unset;
    font-size: 0.58rem !important;
    padding: 3px !important;
  }
  .feHeaderCogIcon .fa-cog {
    font-size: 0.58rem !important;
    padding: 1px !important;
  }
  .feHeaderCogIcon > .dropdown-menu-body {
    padding: 1px !important;
    font-size: smaller;
    top: 0.6rem !important;
  }
  .feHeaderCogIcon a {
    padding: 2px !important;
  }
  .equipmentHeader-navbar {
    padding-left: 4px;
    padding-right: 4px;
  }
  .cnrbtn {
    padding: 3px 6px 3px 6px !important;
  }
}

@media (min-width: 321px) and (max-width: 342px) {
  .feHeader-tabs {
    margin-right: 0px !important;
    display: unset;
    font-size: 0.55rem !important;
    padding: 4px !important;
  }
  .feHeaderCogIcon .fa-cog {
    font-size: 0.55rem !important;
    padding: 1px !important;
  }
  .feHeaderCogIcon > .dropdown-menu-body {
    padding: 1px !important;
    font-size: smaller;
    top: 0.6rem !important;
  }
  .feHeaderCogIcon a {
    padding: 2px !important;
  }
  .equipmentHeader-navbar {
    padding-left: 4px;
    padding-right: 4px;
  }
  .cnrbtn {
    padding: 3px 6px 3px 6px !important;
  }
}

@media (min-width: 300px) and (max-width: 320px) {
  .feHeader-tabs {
    margin-right: 0px !important;
    display: unset;
    font-size: 0.5rem !important;
    padding: 3px !important;
  }
  .feHeaderCogIcon .fa-cog {
    font-size: 0.5rem !important;
    padding: 1px !important;
  }
  .feHeaderCogIcon > .dropdown-menu-body {
    padding: 1px !important;
    font-size: smaller;
    top: 0.6rem !important;
  }
  .feHeaderCogIcon a {
    padding: 2px !important;
  }
  .equipmentHeader-navbar {
    padding-left: 4px;
    padding-right: 4px;
  }
  .cnrbtn {
    padding: 3px 6px 3px 6px !important;
  }
}

/* ###########FEHeader Items responsivness############# */
.content > .fet-container,
.fet-container {
  margin-top: 110px !important;
}

@media (max-width: 1024px) {
  .content > .fet-container,
  .fet-container {
    margin-top: 145px !important;
  }
}
@media (max-width: 527px) {
  .content > .fet-container,
  .fet-container {
    padding: 5px !important;
    margin-top: 160px !important;
  }
}
.content > .fet-container .bg-light-blue {
  background-color: rgba(186, 236, 255, 0.59);
}
.nestedmodal .modal-content {
  margin: 6rem;
}
@media (min-width: 992px) and (max-width:1600px) {
  .fet-container th {
    font-size: 12px;
  }
  .depLable {
    font-weight: 500;
    color: #000;
    font-size:12px !important
  }
  .equipmentFooter {
    font-size: 13px !important;
  }
  [class*="Equip_Requests_tbl_"] th {   
    font-size: 13px;
  }
}
@media (min-width: 768px) and (max-width:992px) {
  .fet-container th {
    font-size: 9px;
  }
  .depLable {
    font-weight: 500;
    color: #000;
    font-size: 9px !important;
  }
  .equipmentFooter {
    font-size: 11px !important;
  }
  [class*="Equip_Requests_tbl_"] th {
      font-size: 12px;
  }
}
@media (min-width: 564px) and (max-width:768px) {
   .fet-container th {
      font-size: 8px;
    }
   .depLable {
      font-weight: 500;
      color: #000;
      font-size: 8px !important;
    }
    .equipmentFooter {
        font-size: 10px !important;
    }
    [class*="Equip_Requests_tbl_"] th {
        font-size: 11px;
    }
}

@media (min-width: 449px) and (max-width: 564px) {
  .fet-container {
    font-size: 12px;
  }
  .fet-container th {
    font-size:7px;
  }
  .depLable {
    font-weight: 500;
    color: #000;
    font-size: 7px !important;
  }
  .equipmentFooter {
      font-size: 9px !important;
  }
  [class*="Equip_Requests_tbl_"] th {
      font-size: 10px;
  }

  .fet-container .feheaderTitle {
    font-size: 15px;
  }

  #addEquipmentModal .feheaderTitle {
    font-size: 11px;
  }
  .feModalHeader h5,
  .feModalHeader h4,
  .feModalHeader h3 {
    font-size: 11px;
  }
  .fet-container h5,
  .fet-container h6 {
    font-size: 13px;
  }
  .AttachmentsHeader h5,
  .AttachmentsHeader h4,
  .AttachmentsHeader span {
    font-size: small;
  }
  .nestedmodal .modal-content {
    margin: 2.7rem;
  }
  .fet-container .filter-selected {
    font-size: x-small;
    margin-bottom: 4px;
  }
}

@media (min-width: 350px) and (max-width: 448px) {
  .fet-container {
    font-size: 11px;
  }
  .fet-container th {
    font-size: 9px;
  }
  .depLable {
    font-weight: 500;
    color: #000;
    font-size: 9px !important;
  }
  .equipmentFooter {
      font-size: 11px !important;
  }
  [class*="Equip_Requests_tbl_"] th {
      font-size: 9px;
  }
  .fet-container .feheaderTitle {
    font-size: 14px;
  }
  #addEquipmentModal .feheaderTitle {
    font-size: 10px;
  }
  .feModalHeader h5,
  .feModalHeader h4,
  .feModalHeader h3 {
    font-size: 10px;
  }
  .fet-container h5,
  .fet-container h6 {
    font-size: 12px;
  }
  .AttachmentsHeader h5,
  .AttachmentsHeader h4,
  .AttachmentsHeader span {
    font-size: smaller;
  }
  .nestedmodal .modal-content {
    margin: 1rem;
  }
  .fet-container .filter-selected {
    font-size: xx-small;
    margin-bottom: 4px;
  }
}

@media (min-width: 280px) and (max-width: 350px) {
  .fet-container {
    font-size: 10px;
  }
  .fet-container th {
    font-size: 8px;
  }
  .depLable {
    font-weight: 500;
    color: #000;
    font-size: 8px !important;
  }
  .equipmentFooter {
    font-size: 10px !important;
  }

  [class*="Equip_Requests_tbl_"] th {
     font-size: 8px;
  }

  .fet-container .feheaderTitle {
    font-size: 13px;
  }
  #addEquipmentModal .feheaderTitle {
    font-size: 9px;
  }
  .feModalHeader h5,
  .feModalHeader h4,
  .feModalHeader h3 {
    font-size: 9px;
  }
  .fet-container h5,
  .fet-container h6 {
    font-size: 11px;
  }
  .AttachmentsHeader h5,
  .AttachmentsHeader h4,
  .AttachmentsHeader span {
    font-size: x-small;
  }
  .nestedmodal .modal-content {
    margin: 0.8rem;
  }
  .fet-container .filter-selected {
    font-size: 0.4rem;
    margin-bottom: 4px;
  }
}

@media (min-width: 280px) and (max-width: 515px) {
  .text_ctrl .md-form {
    margin-bottom: 1.5px !important;
  }
  .eqStatusLable {
    display: contents;
  }
}

.textlink a:link {
  text-decoration: none !important;
}

.textlink a:visited {
  text-decoration: none !important;
}

.textlink a:hover {
  text-decoration: underline !important;
}

.textlink a:active {
  text-decoration: underline !important;
}

.noADData {
  align-self: center !important;
  justify-content: center !important;
  padding-top: 0.9rem !important;
}
.memberEqsTable th {
  font-size: 11px;
}
.memberEqsTable td {
  font-size: 13px;
}
.paddLeftLabel {
  padding-left: 30px;
}

@media (min-width: 280px) and (max-width: 515px) {
  .totalEquipmentsLable {
    display: flex;
    justify-content: center;
    padding-left: 0;
    padding-right: 48px;
  }
}
.equipmentRange > div > div > span:nth-child(3) > span > span {
  display: flex;
  padding: 2.5rem;
}

.AttachmentsHeader > .modal-title {
  color: inherit !important;
}

.feTagsScroll {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 6rem;
}

@media (min-width: 280px) and (max-width: 515px) {
  .equipExpandableView td,
  .equipExpandableView th {
    font-size: 7px;
  }
}

.approvedAmtMt .md-form {
  margin-top: 0.6rem !important;
}
.ttOnModal {
  z-index: 1151 !important;
}
.grayoutText {
  color: #cbcbcb !important;
}
.commmentStyle {
  padding: 1rem 2rem 0 0 !important;
}
.multiBudgetLinesScroll {
  max-height: 30vh;
  overflow: auto;
}
.bgclr {
  background: #f8f9fa !important;
}
.categoryFN {
  max-width: 130px;
  padding-right: 0;
  margin-right: 0;
}
/* .equipCardsTblMainDiv .react-bootstrap-table {
  overflow-x: hidden !important;
}
@media (min-width: 280px) and (max-width: 515px) {
  .equipCardsTblMainDiv .react-bootstrap-table {
    overflow-x: scroll !important;
  }
} */
/* 
table td {
  border: 1px solid #555;
  border-width: 1px 1px 0 0;
}

 
table td:nth-child(4) {
  width: 69%;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
}

table.with-ellipsis td {
  text-overflow: ellipsis;
} */

.Equipments_list_1_Tbl td :not(.depLable) {
  font-size: 0.75rem;
}
.Equipments_list_4_Tbl td :not(.depLable) {
  font-size: 0.75rem;
}
.depLable{
  font-weight: 500;
  color: #000; 
}

.equipmentFooter{
   background-color: silver;
   font-size: medium;
 }
 .filterIconSize{
  font-size: 2.2em !important;
 }
 .reviewIconSize{
  font-size: 1.3em !important;
 }
 .listFilters .MuiInputBase-input {
  font-style: italic;
  font-size: smaller;
    
} 
.listFilters label {
   font-style: italic;
   font-size: smaller;

 }
.inlineDiv {
  display: inline !important;
}

.cardHeaderMainDiv > div > .collapse {
  border: #dee2e6 1px solid !important;
}

.cardHeader {
  width: 100%;
  border-radius: 0;
  padding: 11px 20px;
  text-align: left;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.9px;
  border: none;
  transition: background-color 0.2s ease;
  color: white;
  cursor: pointer;
  background: #6c757d;
  vertical-align: middle;
  justify-content: space-between;
  display: flex;
}

.rowLabelCenter {
  align-self: center !important;
  justify-content: center !important;
  padding: 3rem !important;
}

.stepBtn {
  border: 1px solid #02a499 !important;
}
.stepBtn:disabled {
  background-color: lightgray !important;
  border: 1px solid darkgrey !important;
}

.step1Btn {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.step2Btn {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

#GrievanceAttachmentControl .filepond--list-scroller {
  display: none !important;
}

*,
*:after,
*:before {
    margin: 0;
    padding: 0;
}

.hidden {
    display: none;
}

input[type="button"] {
    margin-top: 20px;
}

.node {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: inline-block;
    margin-left: 10px;
    transition: all 1000ms ease;
    margin-top: 35px;
    margin-bottom: -25px;
}

.stepPara {
    border-left: 3px solid #ced4da;
    margin-left: 19px !important;
    padding-left: 24px !important;
    padding-bottom: 35px !important;
}

.activated {
    box-shadow: 0px 0px 3px 2px rgba(194, 255, 194, 0.8);
}

.divider {
    height: 40px;
    width: 2px;
    margin-left: 4px;
    transition: all 800ms ease;
}

#progress li {
    margin-bottom: -51px;
}

.blue {
    background-color: rgba(82, 165, 255, 1);
}

.meeting {
    background-color: rgb(214, 157, 33);
}

.green {
    background-color: rgba(92, 184, 92, 1);
}

.red {
    background-color: rgba(255, 148, 148, 1);
}

.grey {
    background-color: rgba(201, 201, 201, 1);
}

.border-around {
    border: "1px solid darkgrey !important";
}

.file-upload {
    display: block;
    text-align: center;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 12px;
}

.file-upload .file-select {
    display: block;
    border: 2px solid #ced0da;
    color: #34495e;
    cursor: pointer;
    height: 40px;
    line-height: 40px;
    text-align: left;
    width: 100%;
    background: #ffffff;
    overflow: hidden;
    position: relative;
}

.file-upload .file-select .file-select-button {
    background: #ced0da;
    padding: 0 10px;
    display: inline-block;
    height: 40px;
    line-height: 40px;
}

.file-upload .file-select .file-select-name {
    line-height: 40px;
    display: inline-block;
    padding: 0 10px;
}

.file-upload .file-select:hover {
    border-color: #34495e;
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
}

.file-upload .file-select:hover .file-select-button {
    background: #34495e;
    color: #ffffff;
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
}

.file-upload.active .file-select {
    border-color: #3fa46a;
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
}

.file-upload.active .file-select .file-select-button {
    background: #3fa46a;
    color: #ffffff;
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
}

.file-upload .file-select input[type="file"] {
    z-index: 100;
    cursor: pointer;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    filter: alpha(opacity=0);
}

.file-upload .file-select.file-select-disabled {
    opacity: 0.65;
}

.file-upload .file-select.file-select-disabled:hover {
    cursor: default;
    display: block;
    border: 2px solid #dce4ec;
    color: #34495e;
    cursor: pointer;
    height: 40px;
    line-height: 40px;
    margin-top: 5px;
    text-align: left;
    background: #ffffff;
    overflow: hidden;
    position: relative;
}

.file-upload .file-select.file-select-disabled:hover .file-select-button {
    background: #dce4ec;
    color: #666666;
    padding: 0 10px;
    display: inline-block;
    height: 40px;
    line-height: 40px;
}

.file-upload .file-select.file-select-disabled:hover .file-select-name {
    line-height: 40px;
    display: inline-block;
    padding: 0 10px;
}

.activeTab {
    background-color: #02a499 !important;
    color: white !important;
}

.errspan {
    float: right;
    margin-right: 6px;
    margin-top: -20px;
    position: relative;
    z-index: 2;
    color: red;
}

.topBottonMargin {
    margin-top: 40px !important;
    margin-bottom: 45px !important;
}



.bg-light-grey {
    background-color: #d3d3d3;
}

.not-allowed {
    cursor: help;
}

.modal-content-grievance {
    width: 800px !important;
}

.glog-card {
    border: #dee2e6 1px solid !important;
    width: 529px !important;
    margin-left: 13px !important;
    border-radius: 0px !important;
}

.glog-header {
    padding: 13px 18px 14px 11px !important;
    background: #686e82 !important;
    width: 527px !important;
    border: 1px solid white !important;
    margin-top: 0px !important;
}

.datepickerZindex .react-datepicker-popper {
    z-index:1000!important;
}
/* If the screen size is 600px or less */
@media only screen and (min-width:250px) and (max-width: 576px) {
    
    .stepNumber{
        font-size: 10px !important;
    }
    .stepDetail{
        font-size: 7px !important;
    }
    .box .steps ul li.active div {
        border-color: #167495 !important;
        color: #fff !important;
        background: #167495 !important;
        width: auto !important;
    }

    .box .steps ul li div {
        color: #999;
        padding: 10px 0 15px 45px;
        position: relative;
        background: #f5f5f5;
        width: auto !important;
    }

    .box .steps ul li.done div {
        border-color: #20a8d8 !important;
        color: #fff !important;
        background: #20a8d8 !important;
        width: auto !important;
    }
}
@media only screen and (min-width:576px) and (max-width: 768px) {

    .stepNumber {
        font-size: 11px !important;
    }

    .stepDetail {
        font-size: 8px !important;
    }

    .box .steps ul li.active div {
        border-color: #167495 !important;
        color: #fff !important;
        background: #167495 !important;
        width: auto !important;
    }

    .box .steps ul li div {
        color: #999;
        padding: 10px 0 15px 45px;
        position: relative;
        background: #f5f5f5;
        width: auto !important;
    }

    .box .steps ul li.done div {
        border-color: #20a8d8 !important;
        color: #fff !important;
        background: #20a8d8 !important;
        width: auto !important;
    }
}
@media only screen and (min-width:768px) and (max-width: 992px) {

    .stepNumber {
        font-size: 12px !important;
    }

    .stepDetail {
        font-size: 9px !important;
    }

    .box .steps ul li.active div {
        border-color: #167495 !important;
        color: #fff !important;
        background: #167495 !important;
        width: auto !important;
    }

    .box .steps ul li div {
        color: #999;
        padding: 10px 0 15px 45px;
        position: relative;
        background: #f5f5f5;
        width: auto !important;
    }

    .box .steps ul li.done div {
        border-color: #20a8d8 !important;
        color: #fff !important;
        background: #20a8d8 !important;
        width: auto !important;
    }
}
.box {
    width: auto;
    border: 1px solid #ddd;
    min-height: 65vh;
}

.box .steps {
    border-bottom: 1px solid #ddd;
}

.box .steps ul {
    overflow: hidden;
}

.box .steps ul li div {
    color: #999;
    padding: 10px 0 15px 45px;
    position: relative;
    background: #f5f5f5;
    width: 165px;   
}

.box .steps ul li div span {
    font-size: 13px;
}

.box .steps ul li:first-child div {
    width: 135px;
    padding-left: 15px;
}

.box .steps ul li div::before {
    content: " ";
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
    border-left: 30px solid #ddd;
    position: absolute;
    top: 50%;
    margin-top: -50px;
    left: 100%;
    z-index: 1;
    margin-left: 1px;
}

.box .steps ul li div::after {
    content: " ";
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
    border-left: 30px solid #f5f5f5;
    position: absolute;
    top: 50%;
    margin-top: -50px;
    left: 100%;
    z-index: 2;
}

.box .steps ul li.done div {
    border-color: #20a8d8 !important;
    color: #fff !important;
    background: #20a8d8 !important;
}

.box .steps ul li.done div::after {
    border-left: 30px solid #20a8d8;
}

.box .steps ul li.active div {
    border-color: #167495 !important;
    color: #fff !important;
    background: #167495 !important;
}

.box .steps ul li.active div::after {
    border-left: 30px solid #167495;
}

.box .step-component {
    padding: 20px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.box .btn-component {
    padding: 20px;
    display: flex;
    justify-content: space-between;
} 
 

#success-card {
    position: relative;
    width: 320px;
    display: block;
    margin: 40px auto;
    text-align: center;
    font-family: 'Source Sans Pro', sans-serif;
}

#upper-side {
    padding: 2em;
    background-color: #8BC34A;
    display: block;
    color: #fff;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}

#checkmark {
    font-weight: lighter;
    fill: #fff;
    margin: -3.5em auto auto 20px;
}

#status {
    font-weight: lighter;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 1em;
    margin-top: -.2em;
    margin-bottom: 0;
}

#lower-side {
    padding: 2em 2em 5em 2em;
    background: #fff;
    display: block;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
}

#success-message {
    margin-top: -.5em;
    color: #757575;
    letter-spacing: 1px;
}

#contBtn {
    position: relative;
    top: 1.5em;
    text-decoration: none;
    background: #8bc34a;
    color: #fff;
    margin: auto;
    padding: .8em 3em;
    box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.21);
    border-radius: 25px;
    transition: all .4s ease;
}

#contBtn:hover {
    box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.41);
    transition: all .4s ease;
}
.regisMemActs{
    margin: auto;
    background-color: white;
}
.regisMems{
    margin-top: 2px;
}
.disStyleCheck span{
background-color: #ccc !important;  
}
.clear-icon {
    position: absolute;
    right: 20px;
    top: 2px;
    cursor: pointer;
    display: none;
}
.radioBtn-clear-icon{
position: absolute;
    top: 2px;
    right: 20px;
    cursor: pointer;
}
.custom-select:focus+.clear-icon,
.custom-select:not([value=""])+.clear-icon {
    display: block;
}
.checkoutPage{
    font-size: 2vh;
}
.ct-alias .input-group-text{
    font-size: small;
    font-weight: bold;
}
.paynow{
    height: 18rem;
}
.discountInput{
    padding: 0 3vh 0  3vh;
}
.grpInput{
    height: auto;
}
.md-form label {
    font-size: 0.95rem;
}
.md-form label.active {
    font-size: 0.8rem;
}
.md-form .prefix {
    top: 0.25rem;
    font-size: 1.75rem;
}
.md-form .prefix ~ input,
.md-form .prefix ~ textarea {
    margin-left: 2.5rem;
    width: calc(100% - 2.5rem);
}
.md-form .prefix ~ label {
    margin-left: 2.5rem;
}
.md-form .prefix ~ .form-text {
    margin-left: 2.6rem;
}
.md-form label {
    position: absolute;
    top: 0.65rem;
    left: 0;
    transition: 0.2s ease-out;
    cursor: text;
    color: #979797;
    font-weight: normal;
}
.md-form label.active {
    -webkit-transform: translateY(-140%);
    transform: translateY(-140%);
}
.md-form {
    position: relative;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}
.md-form input[type="text"],
.md-form input[type="password"],
.md-form input[type="email"],
.md-form input[type="url"],
.md-form input[type="time"],
.md-form input[type="date"],
.md-form input[type="datetime-local"],
.md-form input[type="tel"],
.md-form input[type="number"],
.md-form input[type="search-md"],
.md-form input[type="search"],
.md-form textarea.md-textarea {
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    outline: none;
    box-shadow: none;
    border: none;
    border-bottom: 1px solid #ced4da;
    border-radius: 0;
    box-sizing: content-box;
    background-color: transparent;
}
.md-form input[type="text"]:focus:not([readonly]),
.md-form input[type="password"]:focus:not([readonly]),
.md-form input[type="email"]:focus:not([readonly]),
.md-form input[type="url"]:focus:not([readonly]),
.md-form input[type="time"]:focus:not([readonly]),
.md-form input[type="date"]:focus:not([readonly]),
.md-form input[type="datetime-local"]:focus:not([readonly]),
.md-form input[type="tel"]:focus:not([readonly]),
.md-form input[type="number"]:focus:not([readonly]),
.md-form input[type="search-md"]:focus:not([readonly]),
.md-form input[type="search"]:focus:not([readonly]),
.md-form textarea.md-textarea:focus:not([readonly]) {
    box-shadow: 0 1px 0 0 #4285f4;
    border-bottom: 1px solid #4285f4;
}
.md-form input[type="text"]:focus:not([readonly]) + label,
.md-form input[type="password"]:focus:not([readonly]) + label,
.md-form input[type="email"]:focus:not([readonly]) + label,
.md-form input[type="url"]:focus:not([readonly]) + label,
.md-form input[type="time"]:focus:not([readonly]) + label,
.md-form input[type="date"]:focus:not([readonly]) + label,
.md-form input[type="datetime-local"]:focus:not([readonly]) + label,
.md-form input[type="tel"]:focus:not([readonly]) + label,
.md-form input[type="number"]:focus:not([readonly]) + label,
.md-form input[type="search-md"]:focus:not([readonly]) + label,
.md-form input[type="search"]:focus:not([readonly]) + label,
.md-form textarea.md-textarea:focus:not([readonly]) + label {
    color: #4285f4;
}
.md-form input[type="text"] + label:after,
.md-form input[type="password"] + label:after,
.md-form input[type="email"] + label:after,
.md-form input[type="url"] + label:after,
.md-form input[type="time"] + label:after,
.md-form input[type="date"] + label:after,
.md-form input[type="datetime-local"] + label:after,
.md-form input[type="tel"] + label:after,
.md-form input[type="number"] + label:after,
.md-form input[type="search-md"] + label:after,
.md-form input[type="search"] + label:after,
.md-form textarea.md-textarea + label:after {
    content: "";
    position: absolute;
    top: 65px;
    display: block;
    opacity: 0;
    transition: 0.2s opacity ease-out, 0.2s color ease-out;
}
.md-form input[type="text"].valid,
.md-form input[type="text"]:focus.valid,
.md-form input[type="password"].valid,
.md-form input[type="password"]:focus.valid,
.md-form input[type="email"].valid,
.md-form input[type="email"]:focus.valid,
.md-form input[type="url"].valid,
.md-form input[type="url"]:focus.valid,
.md-form input[type="time"].valid,
.md-form input[type="time"]:focus.valid,
.md-form input[type="date"].valid,
.md-form input[type="date"]:focus.valid,
.md-form input[type="datetime-local"].valid,
.md-form input[type="datetime-local"]:focus.valid,
.md-form input[type="tel"].valid,
.md-form input[type="tel"]:focus.valid,
.md-form input[type="number"].valid,
.md-form input[type="number"]:focus.valid,
.md-form input[type="search-md"].valid,
.md-form input[type="search-md"]:focus.valid,
.md-form input[type="search"].valid,
.md-form input[type="search"]:focus.valid,
.md-form textarea.md-textarea.valid,
.md-form textarea.md-textarea:focus.valid {
    border-bottom: 1px solid #00c851;
    box-shadow: 0 1px 0 0 #00c851;
}
.md-form input[type="text"].valid + label:after,
.md-form input[type="text"]:focus.valid + label:after,
.md-form input[type="password"].valid + label:after,
.md-form input[type="password"]:focus.valid + label:after,
.md-form input[type="email"].valid + label:after,
.md-form input[type="email"]:focus.valid + label:after,
.md-form input[type="url"].valid + label:after,
.md-form input[type="url"]:focus.valid + label:after,
.md-form input[type="time"].valid + label:after,
.md-form input[type="time"]:focus.valid + label:after,
.md-form input[type="date"].valid + label:after,
.md-form input[type="date"]:focus.valid + label:after,
.md-form input[type="datetime-local"].valid + label:after,
.md-form input[type="datetime-local"]:focus.valid + label:after,
.md-form input[type="tel"].valid + label:after,
.md-form input[type="tel"]:focus.valid + label:after,
.md-form input[type="number"].valid + label:after,
.md-form input[type="number"]:focus.valid + label:after,
.md-form input[type="search-md"].valid + label:after,
.md-form input[type="search-md"]:focus.valid + label:after,
.md-form input[type="search"].valid + label:after,
.md-form input[type="search"]:focus.valid + label:after,
.md-form textarea.md-textarea.valid + label:after,
.md-form textarea.md-textarea:focus.valid + label:after {
    content: attr(data-success);
    color: #00c851;
    opacity: 1;
}
.md-form input[type="text"].invalid,
.md-form input[type="text"]:focus.invalid,
.md-form input[type="password"].invalid,
.md-form input[type="password"]:focus.invalid,
.md-form input[type="email"].invalid,
.md-form input[type="email"]:focus.invalid,
.md-form input[type="url"].invalid,
.md-form input[type="url"]:focus.invalid,
.md-form input[type="time"].invalid,
.md-form input[type="time"]:focus.invalid,
.md-form input[type="date"].invalid,
.md-form input[type="date"]:focus.invalid,
.md-form input[type="datetime-local"].invalid,
.md-form input[type="datetime-local"]:focus.invalid,
.md-form input[type="tel"].invalid,
.md-form input[type="tel"]:focus.invalid,
.md-form input[type="number"].invalid,
.md-form input[type="number"]:focus.invalid,
.md-form input[type="search-md"].invalid,
.md-form input[type="search-md"]:focus.invalid,
.md-form input[type="search"].invalid,
.md-form input[type="search"]:focus.invalid,
.md-form textarea.md-textarea.invalid,
.md-form textarea.md-textarea:focus.invalid {
    border-bottom: 1px solid #f44336;
    box-shadow: 0 1px 0 0 #f44336;
}
.md-form input[type="text"].invalid + label:after,
.md-form input[type="text"]:focus.invalid + label:after,
.md-form input[type="password"].invalid + label:after,
.md-form input[type="password"]:focus.invalid + label:after,
.md-form input[type="email"].invalid + label:after,
.md-form input[type="email"]:focus.invalid + label:after,
.md-form input[type="url"].invalid + label:after,
.md-form input[type="url"]:focus.invalid + label:after,
.md-form input[type="time"].invalid + label:after,
.md-form input[type="time"]:focus.invalid + label:after,
.md-form input[type="date"].invalid + label:after,
.md-form input[type="date"]:focus.invalid + label:after,
.md-form input[type="datetime-local"].invalid + label:after,
.md-form input[type="datetime-local"]:focus.invalid + label:after,
.md-form input[type="tel"].invalid + label:after,
.md-form input[type="tel"]:focus.invalid + label:after,
.md-form input[type="number"].invalid + label:after,
.md-form input[type="number"]:focus.invalid + label:after,
.md-form input[type="search-md"].invalid + label:after,
.md-form input[type="search-md"]:focus.invalid + label:after,
.md-form input[type="search"].invalid + label:after,
.md-form input[type="search"]:focus.invalid + label:after,
.md-form textarea.md-textarea.invalid + label:after,
.md-form textarea.md-textarea:focus.invalid + label:after {
    content: attr(data-error);
    color: #f44336;
    opacity: 1;
}
.md-form input[type="text"].form-control.valid + label:after,
.md-form input[type="text"].form-control:focus.valid + label:after,
.md-form input[type="password"].form-control.valid + label:after,
.md-form input[type="password"].form-control:focus.valid + label:after,
.md-form input[type="email"].form-control.valid + label:after,
.md-form input[type="email"].form-control:focus.valid + label:after,
.md-form input[type="url"].form-control.valid + label:after,
.md-form input[type="url"].form-control:focus.valid + label:after,
.md-form input[type="time"].form-control.valid + label:after,
.md-form input[type="time"].form-control:focus.valid + label:after,
.md-form input[type="date"].form-control.valid + label:after,
.md-form input[type="date"].form-control:focus.valid + label:after,
.md-form input[type="datetime-local"].form-control.valid + label:after,
.md-form input[type="datetime-local"].form-control:focus.valid + label:after,
.md-form input[type="tel"].form-control.valid + label:after,
.md-form input[type="tel"].form-control:focus.valid + label:after,
.md-form input[type="number"].form-control.valid + label:after,
.md-form input[type="number"].form-control:focus.valid + label:after,
.md-form input[type="search-md"].form-control.valid + label:after,
.md-form input[type="search-md"].form-control:focus.valid + label:after,
.md-form input[type="search"].form-control.valid + label:after,
.md-form input[type="search"].form-control:focus.valid + label:after,
.md-form textarea.md-textarea.form-control.valid + label:after,
.md-form textarea.md-textarea.form-control:focus.valid + label:after {
    top: 3.56rem;
}
.md-form input[type="text"].form-control.invalid + label:after,
.md-form input[type="text"].form-control:focus.invalid + label:after,
.md-form input[type="password"].form-control.invalid + label:after,
.md-form input[type="password"].form-control:focus.invalid + label:after,
.md-form input[type="email"].form-control.invalid + label:after,
.md-form input[type="email"].form-control:focus.invalid + label:after,
.md-form input[type="url"].form-control.invalid + label:after,
.md-form input[type="url"].form-control:focus.invalid + label:after,
.md-form input[type="time"].form-control.invalid + label:after,
.md-form input[type="time"].form-control:focus.invalid + label:after,
.md-form input[type="date"].form-control.invalid + label:after,
.md-form input[type="date"].form-control:focus.invalid + label:after,
.md-form input[type="datetime-local"].form-control.invalid + label:after,
.md-form input[type="datetime-local"].form-control:focus.invalid + label:after,
.md-form input[type="tel"].form-control.invalid + label:after,
.md-form input[type="tel"].form-control:focus.invalid + label:after,
.md-form input[type="number"].form-control.invalid + label:after,
.md-form input[type="number"].form-control:focus.invalid + label:after,
.md-form input[type="search-md"].form-control.invalid + label:after,
.md-form input[type="search-md"].form-control:focus.invalid + label:after,
.md-form input[type="search"].form-control.invalid + label:after,
.md-form input[type="search"].form-control:focus.invalid + label:after,
.md-form textarea.md-textarea.form-control.invalid + label:after,
.md-form textarea.md-textarea.form-control:focus.invalid + label:after {
    top: 3.44rem;
}
.md-form input[type="text"].form-control-lg.valid + label:after,
.md-form input[type="text"].form-control-lg:focus.valid + label:after,
.md-form input[type="password"].form-control-lg.valid + label:after,
.md-form input[type="password"].form-control-lg:focus.valid + label:after,
.md-form input[type="email"].form-control-lg.valid + label:after,
.md-form input[type="email"].form-control-lg:focus.valid + label:after,
.md-form input[type="url"].form-control-lg.valid + label:after,
.md-form input[type="url"].form-control-lg:focus.valid + label:after,
.md-form input[type="time"].form-control-lg.valid + label:after,
.md-form input[type="time"].form-control-lg:focus.valid + label:after,
.md-form input[type="date"].form-control-lg.valid + label:after,
.md-form input[type="date"].form-control-lg:focus.valid + label:after,
.md-form input[type="datetime-local"].form-control-lg.valid + label:after,
.md-form input[type="datetime-local"].form-control-lg:focus.valid + label:after,
.md-form input[type="tel"].form-control-lg.valid + label:after,
.md-form input[type="tel"].form-control-lg:focus.valid + label:after,
.md-form input[type="number"].form-control-lg.valid + label:after,
.md-form input[type="number"].form-control-lg:focus.valid + label:after,
.md-form input[type="search-md"].form-control-lg.valid + label:after,
.md-form input[type="search-md"].form-control-lg:focus.valid + label:after,
.md-form input[type="search"].form-control-lg.valid + label:after,
.md-form input[type="search"].form-control-lg:focus.valid + label:after,
.md-form textarea.md-textarea.form-control-lg.valid + label:after,
.md-form textarea.md-textarea.form-control-lg:focus.valid + label:after {
    top: 4.1rem;
}
.md-form input[type="text"].form-control-lg.invalid + label:after,
.md-form input[type="text"].form-control-lg:focus.invalid + label:after,
.md-form input[type="password"].form-control-lg.invalid + label:after,
.md-form input[type="password"].form-control-lg:focus.invalid + label:after,
.md-form input[type="email"].form-control-lg.invalid + label:after,
.md-form input[type="email"].form-control-lg:focus.invalid + label:after,
.md-form input[type="url"].form-control-lg.invalid + label:after,
.md-form input[type="url"].form-control-lg:focus.invalid + label:after,
.md-form input[type="time"].form-control-lg.invalid + label:after,
.md-form input[type="time"].form-control-lg:focus.invalid + label:after,
.md-form input[type="date"].form-control-lg.invalid + label:after,
.md-form input[type="date"].form-control-lg:focus.invalid + label:after,
.md-form input[type="datetime-local"].form-control-lg.invalid + label:after,
.md-form
    input[type="datetime-local"].form-control-lg:focus.invalid
    + label:after,
.md-form input[type="tel"].form-control-lg.invalid + label:after,
.md-form input[type="tel"].form-control-lg:focus.invalid + label:after,
.md-form input[type="number"].form-control-lg.invalid + label:after,
.md-form input[type="number"].form-control-lg:focus.invalid + label:after,
.md-form input[type="search-md"].form-control-lg.invalid + label:after,
.md-form input[type="search-md"].form-control-lg:focus.invalid + label:after,
.md-form input[type="search"].form-control-lg.invalid + label:after,
.md-form input[type="search"].form-control-lg:focus.invalid + label:after,
.md-form textarea.md-textarea.form-control-lg.invalid + label:after,
.md-form textarea.md-textarea.form-control-lg:focus.invalid + label:after {
    top: 4rem;
}
.md-form input[type="text"].form-control-sm.valid + label:after,
.md-form input[type="text"].form-control-sm:focus.valid + label:after,
.md-form input[type="password"].form-control-sm.valid + label:after,
.md-form input[type="password"].form-control-sm:focus.valid + label:after,
.md-form input[type="email"].form-control-sm.valid + label:after,
.md-form input[type="email"].form-control-sm:focus.valid + label:after,
.md-form input[type="url"].form-control-sm.valid + label:after,
.md-form input[type="url"].form-control-sm:focus.valid + label:after,
.md-form input[type="time"].form-control-sm.valid + label:after,
.md-form input[type="time"].form-control-sm:focus.valid + label:after,
.md-form input[type="date"].form-control-sm.valid + label:after,
.md-form input[type="date"].form-control-sm:focus.valid + label:after,
.md-form input[type="datetime-local"].form-control-sm.valid + label:after,
.md-form input[type="datetime-local"].form-control-sm:focus.valid + label:after,
.md-form input[type="tel"].form-control-sm.valid + label:after,
.md-form input[type="tel"].form-control-sm:focus.valid + label:after,
.md-form input[type="number"].form-control-sm.valid + label:after,
.md-form input[type="number"].form-control-sm:focus.valid + label:after,
.md-form input[type="search-md"].form-control-sm.valid + label:after,
.md-form input[type="search-md"].form-control-sm:focus.valid + label:after,
.md-form input[type="search"].form-control-sm.valid + label:after,
.md-form input[type="search"].form-control-sm:focus.valid + label:after,
.md-form textarea.md-textarea.form-control-sm.valid + label:after,
.md-form textarea.md-textarea.form-control-sm:focus.valid + label:after {
    top: 3.2rem;
}
.md-form input[type="text"].form-control-sm.invalid + label:after,
.md-form input[type="text"].form-control-sm:focus.invalid + label:after,
.md-form input[type="password"].form-control-sm.invalid + label:after,
.md-form input[type="password"].form-control-sm:focus.invalid + label:after,
.md-form input[type="email"].form-control-sm.invalid + label:after,
.md-form input[type="email"].form-control-sm:focus.invalid + label:after,
.md-form input[type="url"].form-control-sm.invalid + label:after,
.md-form input[type="url"].form-control-sm:focus.invalid + label:after,
.md-form input[type="time"].form-control-sm.invalid + label:after,
.md-form input[type="time"].form-control-sm:focus.invalid + label:after,
.md-form input[type="date"].form-control-sm.invalid + label:after,
.md-form input[type="date"].form-control-sm:focus.invalid + label:after,
.md-form input[type="datetime-local"].form-control-sm.invalid + label:after,
.md-form
    input[type="datetime-local"].form-control-sm:focus.invalid
    + label:after,
.md-form input[type="tel"].form-control-sm.invalid + label:after,
.md-form input[type="tel"].form-control-sm:focus.invalid + label:after,
.md-form input[type="number"].form-control-sm.invalid + label:after,
.md-form input[type="number"].form-control-sm:focus.invalid + label:after,
.md-form input[type="search-md"].form-control-sm.invalid + label:after,
.md-form input[type="search-md"].form-control-sm:focus.invalid + label:after,
.md-form input[type="search"].form-control-sm.invalid + label:after,
.md-form input[type="search"].form-control-sm:focus.invalid + label:after,
.md-form textarea.md-textarea.form-control-sm.invalid + label:after,
.md-form textarea.md-textarea.form-control-sm:focus.invalid + label:after {
    top: 3.1rem;
}
.md-form .was-validated input[type="text"]:valid + label {
    color: #00c851 !important;
}
.md-form .was-validated input[type="text"]:invalid + label {
    color: #f44336 !important;
}
.md-form .was-validated .form-control:valid:focus {
    box-shadow: 0 1px 0 0 #00c851 !important;
}
.md-form .was-validated .form-control:valid {
    border-color: #00c851 !important;
}
.md-form .was-validated .form-control:invalid:focus {
    box-shadow: 0 1px 0 0 #f44336 !important;
}
.md-form .was-validated .form-control:invalid {
    border-color: #f44336 !important;
}
.md-form .form-control {
    margin: 0 0 0.5rem 0;
    border-radius: 0;
    padding: 0.3rem 0 0.55rem 0;
    background-image: none;
    background-color: transparent;
    height: auto;
}
.md-form .form-control:focus {
    box-shadow: none;
    background: transparent;
}
.md-form .form-control:disabled,
.md-form .form-control[readonly] {
    border-bottom: 1px solid #bdbdbd;
    background-color: transparent;
}
.md-form .validate {
    margin-bottom: 2.5rem;
}
.md-accordion .card .card-header {
    border-bottom-color: #ccc;
    padding: 1rem 1.5rem 0.8rem 0;
    background: 0 0;
}
.md-accordion .card {
    border: none;
    box-shadow: none;
}

/** @format */

.App {
  text-align: center;
}

.dispNone {
  display: none;
}

.btn-primary {
  background-color: #23325b;
}

.btn-primary:hover {
  background-color: #2f4daa;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.dis-none {
  display: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.disabled {
  background-color: #f7f7f7;
  cursor: text;
}

label {
  font-weight: 400;
}

.loading {
  position: fixed;
  z-index: 9999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.react-bootstrap-table-pagination ul.pagination {
  float: right;
}

.react-bootstrap-table .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.react-bootstrap-table th>.react-bootstrap-table-sort-order>.caret {
  margin: 10px 6.5px;
}

.react-bootstrap-table th.sortable {
  cursor: pointer;
}

.react-bootstrap-table th .order>.dropdown>.caret {
  margin: 10px 0 10px 5px;
  color: #cccccc;
}

.react-bootstrap-table th .order>.dropup>.caret {
  margin: 10px 0;
  color: #cccccc;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  content: "";
  border-top: 0px;
  border-bottom: 4px dashed;
}

.bare-button {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  cursor: pointer;
}

.custom-control-label::before,
.custom-control-label::after {
  width: 1.2rem;
  height: 1.2rem;
}

.custom-control-label-override::before,
.custom-control-label-override::after {
  width: 0.9rem !important;
  height: 0.9rem !important;
}

.custom-control-label {
  line-height: 28px;
}
.line-height-inherit {
  line-height: inherit;
}
.custom-checkbox {
  display: inline-block;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.15rem;
}

.app-search button {
  position: absolute;
  top: 0px;
  display: block;
  color: #ffffff;
  font-size: 13px;
  border: none;
  right: 0;
  cursor: pointer;
  background: #759fbc;
  height: 34px;
  padding: 0 20px;
  border-radius: 0 30px 30px 0;
}

.as-link {
  font-size: 13px;
  padding-top: 5px;
}

.advance-search .md-form {
  margin: 5px 0;
}

label.search-label {
  width: 100%;
}

.bbLight {
  border-bottom: 1px solid #eee;
  padding-bottom: 15px;
}

div.bbLight:last-child {
  border: none !important;
}

.md-form .form-control.is-invalid,
.was-validated .md-form .form-control:invalid,
.form-control.is-valid,
.was-validated .form-control:valid {
  padding-right: 0;
}

.enlarged #wrapper #sidebar-menu ul ul {
  position: absolute;
  left: -200px;
}

.r1 {
  background-color: #ffffff;
  padding: 30px 0;
  min-height: 80px;
}

.r2 {
  background-color: #f7f7f7;
  padding: 30px 0;
  min-height: 80px;
}

.r3 {
  background-color: #eeeeee;
  padding: 30px 0;
  min-height: 80px;
}

input[switch]+label.size2 {
  width: 70px;
}

input[switch]:checked+label.size2:after {
  left: 47px;
}

.actionCol {
  width: 80px !important;
  text-align: center;
}

.react-bootstrap-table {
  overflow-x: auto !important;
}

.enlarged .side-menu {
  position: fixed;
}

.dg-none th {
  height: 0 !important;
  padding: 0;
  border: none !important;
}

#teamMembers>.react-bootstrap-table .table:first-child {
  margin-bottom: -1px;
}

#teamMembers .react-bootstrap-table-pagination {
  margin-top: 15px;
}

.disabletbody {
  opacity: 0.5;
  pointer-events: none;
}

.select__menu {
  margin-top: 5px !important;
  z-index: 999999;
}

.bg-primary {
  background-color: #759fbc !important;
}

#teamMembers .table {
  margin-bottom: 0;
}

.search-btn-panel {
  margin-top: 46px;
}

.search-link {
  margin: 0 15px;
  font-size: 16px;
  color: #759fbc !important;
  font-weight: 400;
}

.search-link:hover {
  color: #759fbc;
}

.search-link-line {
  font-size: 20px;
  color: #759fbc;
  font-weight: 400;
}

.actionCol .btn {
  padding: 0.15rem 0.3rem;
  font-size: 8px;
}

.actionCol .ion {
  font-size: 10px;
}

.search-btn-panel-team {
  margin-top: -4px;
}

.search-btn-panel-team .dropdown {
  display: initial;
}

.d-tabs {
  padding: 3px 7px;
}

.password-icon {
  position: absolute;
  right: 18px;
  top: 270px;
  cursor: pointer;
}

.inputBorderRed {
  border: solid 1px red;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range {
  background-color: #02a499 !important;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover {
  background-color: #01766e !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected {
  background-color: #02a499 !important;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover {
  background-color: #02a499 !important;
}

.topUp {
  top: -0.3rem !important;
}

.topUpMinor {
  top: -1rem !important;
}

.verifySms {
  margin-bottom: 1px;
}

.step4Input {
  margin-bottom: 1px;
}

.headerPos {
  position: relative;
  top: 0.5rem;
  font-size: 133%;
}

.signUpStepper {
  position: absolute;
  top: 6.5rem;
  left: 0.5rem;
  font-size: 92%;
}

.step1Parent {
  margin-top: 3rem;
}

.lastrow {
  display: inline-block;
}

.spinnerCard {
  position: absolute;
  top: 50%;
  left: 50%;
  background-size: 100%;
}

.spinnerHeight {
  height: 15rem;
}

.reviewInfoHeading {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}

.oneLine {
  white-space: nowrap;
}

.mobile-margin-cmm {
  margin-left: 1rem;
}

.bucketSpecialTbl>.react-bootstrap-table {
  overflow-x: hidden !important;
}

._loading_overlay_wrapper>.react-bootstrap-table {
  min-height: 8rem !important;
}

#Sub_Teams_2_Records>div>div._loading_overlay_overlay {
  margin-top: 2rem !important;
}

.css-79elbk>div {
  min-height: 6rem !important;
}

.messageText {
  font-size: 95%;
}

.reduceLetterSpace {
  letter-spacing: 0.1px !important;
}

@media (max-width: 575px) {
  .tblBucket {
    width: 50% !important;
  }
}

.reviewScreenHeader>thead>tr>th {
  color: white !important;
}

.reviewParentDiv>table {
  margin-bottom: 0;
}

.reviewIcon {
  color: #ffc107;
}

.bgFieldMessage {
  background: #00000045;
  height: 2rem;
  padding-top: 0.4rem;
}

.fontSmaller {
  font-size: smaller;
}

.parent-expand {
  background-color: #337ab733;
}

.csvIconSize {
  font-size: 1.5rem;
  display: flex;
  cursor: pointer !important;
}

.NoDataFoundTable {
  align-self: center !important;
  justify-content: center !important;
  padding: 1rem !important;
}
.FoFManagersTable {
  align-self: center !important;
  justify-content: end !important;
  padding: 1rem !important;
}
.NoUpdatedFieldFound {
  align-self: center !important;
  justify-content: center !important;
}

.yearPP {
  display: inline-block;
  min-width: 4rem;
}

.height17px {
  height: 17px !important;
}

.removedSection {
  background: rgb(0 0 0 / 25%) !important;
}

.inlineTable {
  display: inline-table;
}

.progress {
  height: 1.4rem;
  width: 75%;
  font-size: x-small;
}

.progress-bar {
  background-color: #626ed4;
  /* min-width: fit-content;
  max-width: 105%; */
  /* color: black; */
}

.ignored_pg {
  min-width: -webkit-fit-content !important;
  min-width: -moz-fit-content !important;
  min-width: fit-content !important;
  color: black;
}

.error_pg {
  min-width: -webkit-fit-content !important;
  min-width: -moz-fit-content !important;
  min-width: fit-content !important;
  color: black;
}

.updated_pg {
  min-width: -webkit-fit-content !important;
  min-width: -moz-fit-content !important;
  min-width: fit-content !important;
  color: black;
}

.removed_pg {
  min-width: -webkit-fit-content !important;
  min-width: -moz-fit-content !important;
  min-width: fit-content !important;
  color: black;
}

.showCV {
  font-size: small;
}

.showCV:visited {
  text-decoration: none !important;
}

.showCV:hover {
  text-decoration: underline !important;
  font-weight: 575;
}

.showCV:active {
  text-decoration: underline !important;
  font-weight: 575;
}

/*Membership and useraccount cards*/
.ps-column-mac {
  flex: 25% 1;
}

/* On screens that are 992px wide or less, go from four columns to two columns */
@media screen and (max-width: 767px) {
  .ps-column-mac {
    flex: 100% 1;
  }
}

/* On screens that are 600px wide or less, make the columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .ps-row-mac {
    flex-direction: column;
  }
}

/*Membership and useraccount cards ENDING*/
/* @media screen and (max-width: 782px) {
  .resetPassMobile {
    padding-right: 8px;
    padding-left: 0;
    font-size: smaller;
    padding-top: 3px;
    padding-bottom: 3px;
  }
} */
@media screen and (max-width: 782px) {
  .resetPassParent {
    padding-right: 0;
    padding-left: 0;
  }
}

@media screen and (max-width: 782px) {
  .restPwdBtn {
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 6px;
    padding-right: 6px;
    /* float: right; */
  }
}

@media screen and (max-width: 782px) {
  #username-inline {
    font-size: small;
  }
}

/* @media screen and (max-width: 1414px) {
  .memtype {
    font-size: small;
  }
} */

@media screen and (max-width: 576px) {
  .restPwdBtn {
    float: right;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media screen and (max-width: 567px) {
  .resetPassMobile {
    font-size: initial;
    padding-right: 0;
  }
}

@media screen and (max-width: 767px) {
  #username-inline {
    font-size: initial;
  }
}

@media screen and (max-width: 1414px) {
  .pdMemType {
    padding-right: 0;
  }
}

.editMemPen {
  padding-top: 0;
  padding-left: 1rem;
  padding-bottom: 1rem;
}

.text_ctrl>.form-group>.md-form {
  margin-top: 0.6rem !important;
}

.expandTbl {
  padding-bottom: 1rem;
  padding-left: 5rem;
  padding-right: 5rem;
  font-size: small;
  font-style: italic;
}

.disableDownloadIcons {
  pointer-events: none !important;
  color: lightgray !important;
}

.modal-45w {
  max-width: 45%;
}

.modal-20w {
  max-width: 20%;
}

.modal-52w {
  max-width: 52%;
}

.modal-55w {
  max-width: 55%;
}
.modal-60w {
  max-width: 60%;
}
.modal-85w {
  max-width: 85%;
}
.modal-95w {
    max-width: 95%;
}
.modal-110{
  min-height: 120%;
}

.table>caption {
  text-align: left;
  caption-side: top;
  padding: 0;
  color: white;
  background-color: #686e82;
}

.table>.noData>caption {
  text-align: left;
  caption-side: top;
  padding: 0;
  color: white;
  background-color: #686e82;
}

.sub_tbl_caption {
  margin: 5px;
  padding: 3px;
}
#emailConfigTabs-tab-subscribers > i,
#emailConfigTabs-tab-unsubscribed > i,
#emailConfigTabs-tab-settings > i,
#emailConfigTabs-tab-activityLogs>i {
  font-size: large;
}

.emailConfigHeader {
  text-decoration: underline;
  cursor: pointer;
}

.emailConfigHeaderHightLight {
  color: #626ed4;
}

.mainHeaderLine {
  display: inline-flex;
  align-items: center;
}

.nested-cs-modal {
  background: rgba(0, 0, 0, 0.3);
}

.unsubscrActionBtn {
  font-size: 0.69rem
}

.reviewRegRequestBody {
  background: rgba(0, 0, 0, 0.08);
}

.Ddlfilters {
  padding: 0.4rem 0.4rem 0.4rem 0.4rem;
  background: #e9ecef;
  margin-bottom: 1rem;
  max-height: 8em;
  overflow: hidden;
  overflow-y: scroll;
}
.font-weight-450{
  font-weight: 450;
}
#manageSubscribe{
  color:#626ed4;
  cursor: pointer;
}
.nestedComps{
  margin-left: -1rem;
}
.toggle.android {
  border-radius: 5px;

}

.toggle.android .toggle-handle {
  border-radius: 5px;
}
.toggle-group label{
  font-size: small;
  /* width: max-content; */
}
.action-icons{
  float: right;
  padding-right: 0.6rem;
}
.action-def-mems{
  float: right;
  padding-right: 2rem;
} 
.form-control.DefaultMemberSearchField::-webkit-input-placeholder {
  font-size: smaller;  
} 
.form-control.DefaultMemberSearchField::placeholder {
  font-size: smaller;  
}

@media screen and (max-width: 600px) {
.mobileActions {
    width: -webkit-max-content;
    width: max-content;
  }
}
@media screen and (max-width: 600px) {
  .delbtnel {
    margin-top: 1vh;
  }
}

@media screen and (min-width: 768px) {
  .subscribersModalCustomWidth {
      max-width: 65%;
    }
}
@media screen and (min-width: 1400px) {
  .subscribersModalCustomWidth {
    max-width: 63%;
  }
}

@media screen and (min-width: 1500px) {
  .subscribersModalCustomWidth {
    max-width: 69%;
  }
}
@media screen and (min-width: 1650px) {
  .subscribersModalCustomWidth {
    max-width: 46%;
  }
}
@media screen and (min-width: 1700px) {
  .subscribersModalCustomWidth {
    max-width: 58%;
  }
}
.btnElAction{
  font-size: small;
}

.emailConfChkBox {
  margin-left: 2px;
}
